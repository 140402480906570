//
// COLORS
//
$color-background-white1: rgba(255, 255, 255, 1);
$color-background-white2: rgba(255, 255, 255, 0.75);
$color-background-black1: #000000;
$color-background-black2: #181818;
$color-text-white1: rgba(255, 255, 255, 1);
$color-text-white2: rgba(255, 255, 255, 0.75);
$color-text-black1: rgb(0, 0, 0);
$color-text-black2: rgba(0, 0, 0, 0.75);
$color-primary: #e64653;
$color-accent: #6a5df5;

.text-white1 {
  color: $color-text-white1 !important;
}
.text-white2 {
  color: $color-text-white2 !important;
}
.text-black1 {
  color: $color-text-black1 !important;
}
.text-black2 {
  color: $color-text-black2 !important;
}
.text-primary {
  color: $color-primary !important;
}
.text-accent {
  color: $color-accent !important;
}

//
// FONT SIZES
//
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-size-xl: 28px;

.font-size-xs {
  font-size: $font-size-xs;
}
.font-size-sm {
  font-size: $font-size-sm;
}
.font-size-md {
  font-size: $font-size-md;
}
.font-size-lg {
  font-size: $font-size-lg;
}
.font-size-xl {
  font-size: $font-size-xl;
}

//
// HEADINGS
//

.theme-font {
  font-family: "Chakra Petch" !important;
}

.hero-heading-font {
  font-family: "Chakra Petch" !important;
  font-size: 70px !important;
  margin-bottom: 32px;
  color: $color-text-white1;
  font-weight: 900;
}

.home-hero-heading-font {
  font-size: 70px !important;
}

.hero-subheading-font {
  font-family: "Chakra Petch" !important;
  font-size: 42px !important;
  margin-bottom: 32px;
  margin-top: -16px;
  color: $color-text-white1;
}

.heading-font {
  font-family: "Chakra Petch" !important;
  font-size: 50px !important;
  color: $color-text-white1;
  font-weight: 900;
  margin: 0px;
  position: relative;
}

.subheading-font {
  font-size: 20px !important;
  margin-top: 32px;
  margin-bottom: 32px;
  color: $color-text-white1;
  line-height: 30px;
}

@media (max-width: 520px) {
  .hero-heading-font {
    font-size: 48px !important;
  }

  .home-hero-heading-font {
    font-size: 28px !important;
  }

  .hero-subheading-font {
    font-size: 28px !important;
  }

  .heading-font {
    font-size: 32px !important;
  }

  .subheading-font {
    font-size: 18px !important;
    line-height: 26px;
  }
}

//
// COMMON STYLES
//
html,
body {
  font-family: "Open Sans", sans-serif !important;
  background: $color-background-black1;
  color: $color-text-white1;
  font-size: $font-size-md;
  margin: 0;
  padding: 0px;
  font-weight: 400;
  overflow-x: hidden;
  height: 100%;
}

button {
  font-family: "Chakra Petch" !important;
}

.gradient1 {
  background: rgb(230, 70, 83);
  background: -moz-linear-gradient(
    90deg,
    rgba(230, 70, 83, 1) 0%,
    rgba(106, 93, 245, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(230, 70, 83, 1) 0%,
    rgba(106, 93, 245, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(230, 70, 83, 1) 0%,
    rgba(106, 93, 245, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e64653",endColorstr="#6a5df5",GradientType=1);
}

.gradient2 {
  background: rgb(230, 70, 83);
  background: -moz-linear-gradient(
    175deg,
    rgba(230, 70, 83, 1) 0%,
    rgba(106, 93, 245, 1) 100%
  );
  background: -webkit-linear-gradient(
    175deg,
    rgba(230, 70, 83, 1) 0%,
    rgba(106, 93, 245, 1) 100%
  );
  background: linear-gradient(
    175deg,
    rgba(230, 70, 83, 1) 0%,
    rgba(106, 93, 245, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e64653",endColorstr="#6a5df5",GradientType=1);
}

.container {
  max-width: 1140px;
}

.mobile-spacer {
  display: none;
  padding: 24px;
}

@media (max-width: 991px) {
  .mobile-spacer {
    display: block;
  }
}

//
// MUI
//

.MuiButton-root {
  text-transform: none !important;
  font-weight: bold !important;
  border-radius: 5px !important;
}

.MuiPaper-root {
  max-width: 70% !important;
  max-height: 100% !important;
  min-width: 70% !important;
}

.MuiInputLabel-root {
  width: 100%;
  text-align: left;
  font-family: "Open Sans", sans-serif !important;
}

.MuiInputBase-root {
  width: 100%;
  text-align: left;
}

.MuiInputBase-input {
  font-family: "Open Sans", sans-serif !important;
}

@media (max-width: 700px) {
  .MuiPaper-root {
    max-width: 100% !important;
    max-height: 100% !important;
    min-width: 90% !important;
  }
}

.MuiDrawer-paper {
  min-width: 100% !important;
  overflow-x: hidden;
  background: $color-background-black1 !important;
}

.MuiDialog-container {
  iframe {
    width: 100%;
    height: 100%;
    min-height: 480px;
  }

  @media (max-width: 700px) {
    iframe {
      width: 100%;
      height: 100%;
      min-height: 240px;
    }
  }
}

.MuiDivider-root {
  border-color: rgba(255, 255, 255, 0.5);
}

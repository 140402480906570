@import "../../index.scss";

.careers-component {
  .section-main {
    color: $color-text-black1;
    padding: 74px 0px 0px 0px;

    .wave-container {
      padding: 64px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button-learn-more {
      border-radius: 64px !important;
      text-transform: uppercase !important;
    }
  }

  .link-text {
    color: $color-primary;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: $color-primary;
    }
  }

  .section-core-values {
    padding: 74px 0px 32px 0px;
    color: $color-text-black1;

    .card-title {
      color: $color-text-white1;
      font-size: $font-size-lg;
      margin-bottom: 0px;
      vertical-align: -3px;
      font-family: "Chakra Petch" !important;
    }

    .icon-container {
      display: inline-block;
      padding: 16px;
      width: 100%;
      margin-bottom: 16px;
      min-height: 156px;

      .svg-inline--fa {
        color: $color-primary;
        font-size: 48px;
        width: 48px;
        height: 48px;
      }

      b {
        display: block;
        margin: 16px 0px 8px 0px;
        font-size: $font-size-lg;
        color: $color-text-white1;
      }
    }
  }

  .section-how-we-hire {
    padding: 0px 0px 74px 0px;

    .section-description {
      margin-bottom: 48px;
      display: inline-block;
    }

    .icon-container {
      padding: 12px;
      border-radius: 5px;
      // background: $color-accent;
      margin-bottom: 16px;
      display: inline-block;
      height: 48px;
      width: 48px;
      text-align: center;
      position: relative;
      transform: rotate(45deg);
    }

    .svg-inline--fa {
      color: $color-text-white1;
      font-size: $font-size-lg;
      width: $font-size-lg;
      height: $font-size-lg;
      display: inline-block;
      transform: rotate(-45deg);
    }

    .how-we-hire-container {
      position: relative;
      .timeline {
        background: #e1e2e5;
        height: 83%;
        position: absolute;
        width: 1px;
        left: calc(16.66666667% - 44px);
      }
      @media (max-width: 991px) {
        .timeline {
          left: calc(25% - 42px);
        }
      }
    }

    .vertical-timeline-element-content-arrow {
      content: "";
      position: absolute;
      top: 16px;
      right: 100%;
      height: 0;
      width: 0;
      border: 7px solid transparent;
      border-right: 7px solid #1e1958;
    }

    .description {
      color: $color-text-white1;
      background: #1e1958;
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 16px;
      margin-left: 32px;
      position: relative;

      &.indented {
        margin-left: 64px;
      }

      @media (max-width: 991px) {
        margin-left: 0px;

        &.indented {
          margin-left: 32px;
        }
      }

      b {
        color: $color-text-white1;
        margin: 0px 0px 0px 0px;
        display: inline-block;
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 0px;
        font-size: $font-size-sm;
      }
    }
  }

  .section-benefits {
    padding: 0px 0px 0px 0px;

    .card-title {
      color: $color-text-white1;
      font-size: $font-size-lg;
      margin-bottom: 0px;
      vertical-align: -3px;
      font-family: "Chakra Petch" !important;
    }

    .icon-container {
      padding: 24px 8px 8px 8px;
      border-radius: 100%;
      // background: $color-primary;
      margin-bottom: 8px;
      display: inline-block;

      & + div {
        margin-bottom: 32px;
      }
    }

    .svg-inline--fa {
      color: $color-primary;
      font-size: 48px;
      width: 48px;
      height: 48px;
    }

    hr {
      margin-bottom: 16px;
    }
  }

  .section-faq {
    padding: 74px 0px 0px 0px;

    b {
      display: block;
      margin: 16px 0px;
      color: $color-text-white1;
    }
    b + div {
      color: $color-text-white2;
    }

    .faq-tile {
      color: $color-text-black1;
      // background: $color-accent;
      padding: 16px 24px;
      border-radius: 8px;
      // margin-bottom: 16px;
      min-height: 220px;

      @media (max-width: 1080px) {
        min-height: 272px;
      }

      @media (max-width: 880px) {
        min-height: 296px;
      }

      @media (max-width: 790px) {
        min-height: 344px;
      }

      @media (max-width: 767px) {
        min-height: auto;
      }
    }
  }

  .section-apply {
    padding: 74px 0px;

    .apply-card {
      border-radius: 16px;
      padding: 32px;
      background: $color-background-black1;

      button {
        width: 100%;
      }
    }

    .MuiFormLabel-root {
      margin: 8px 0px;
    }

    .MuiFormLabel-root,
    .MuiInputLabel-root,
    .MuiFormControl-root {
      color: $color-text-white2;
    }
    .MuiInputBase-root,
    .MuiInputBase-input {
      color: $color-text-white1;
    }

    .MuiInputBase-root.Mui-focused::after {
      border-bottom: 1px solid $color-text-white2;
      transform: scaleX(1) translateX(0);
    }

    .MuiInputBase-root::after {
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      transform: scaleX(1) translateX(0);
    }
  }

  .form-input {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    outline: none;
    font-size: $font-size-md;
    font-family: "Inter", sans-serif;
    transition-duration: 0.3s;
    &:focus {
      border-color: #000000;
    }
  }

  input[type="file"]::file-selector-button {
    margin: 4px 8px 4px 0px;
    border: none;
    background: $color-primary;
    padding: 6px 12px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: $font-size-sm;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  input[type="file"]::file-selector-button:hover {
    background: $color-primary;
  }
}

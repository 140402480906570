@import "../../index.scss";

.heading-component {
  position: relative;
  text-align: center;

  .gradient1 {
    display: inline-block;
    position: absolute;
    bottom: 11px;
    height: 4px;
  }
}

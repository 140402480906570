@import "../../index.scss";

.progress-slider {
  position: relative;
  color: $color-text-white2;
  padding: 0px 0px;
  video {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  @media (max-width: 1200px) {
    video {
      height: 600px;
    }
  }

  @media (max-width: 991px) {
    video {
      height: 500px;
    }
  }

  @media (max-width: 767px) {
    padding: 0px 16px;
    video {
      height: 400px;
    }
  }

  @media (max-width: 600px) {
    video {
      height: 320px;
    }
  }

  @media (max-width: 400px) {
    video {
      height: 180px;
    }
  }

  h2 {
    font-size: $font-size-lg;
  }

  .slider-dots {
    width: calc(100% + 16px);
    margin: 0 -8px;
    padding: 0;
    text-align: center;
    list-style: none;

    li {
      display: inline-block;
      width: calc(25% - 16px);
      margin: 0 8px;
    }

    button {
      position: relative;
      width: 100%;
      height: 4px;
      border: 0;
      background-color: #ccc;
      font-size: 0;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 0%;
        height: 100%;
      }
    }

    .slick-active {
      button:after {
        background-color: $color-accent;
        animation: progress 15s linear forwards;
      }
    }
  }

  @keyframes progress {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .video-container {
    padding: 16px 0px;
    video {
      border-radius: 16px;
    }

    @media (max-width: 767px) {
      padding: 0px;
    }

    button {
      position: absolute;
      right: 16px;
      bottom: 36px;
    }
  }

  .overlay {
    position: absolute;
    top: 64px;
    left: 64px;
    background-color: $color-primary;
    color: $color-text-white1;
    border-radius: 8px;
    padding: 16px;
    width: 280px;

    @media (max-width: 991px) {
      top: -64px;
      left: 16px;
      width: calc(100% - 32px);
    }

    h4 {
      font-size: $font-size-md;
      font-weight: 600;
      display: inline-block;
      margin-left: 8px;
      margin-bottom: 0px;
      vertical-align: -1px;
    }
    p {
      font-size: $font-size-md;
      margin-top: 16px;
    }
  }

  .description {
    cursor: pointer;
    h4 {
      font-family: "Chakra Petch" !important;
    }
  }
}

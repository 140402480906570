@import "../../index.scss";

.footer-component {
  padding: 32px 0px 32px 0px;

  .text-white1 {
    margin-bottom: 8px;
  }

  .logo-image {
    height: 48px;
    margin-right: 16px;
    display: inline-block;
  }

  .logo-text {
    display: inline-block;
    font-size: 28px;
    color: $color-text-white1;
    vertical-align: -4px;
    letter-spacing: -1px;
    font-weight: 600;
  }

  // .nav-menu {
  //   a {
  //     display: inline-block;
  //     text-align: center;
  //     // width: 118px;
  //     padding: 0px 16px;
  //     text-decoration: none;
  //     button {
  //       color: $color-text-white1;
  //       font-size: 16px;
  //       font-weight: 600 !important;
  //       &:hover {
  //         button {
  //           color: $color-primary !important;
  //         }
  //       }
  //     }
  //     &.active {
  //       button {
  //         color: $color-primary !important;
  //       }
  //     }
  //   }
  // }

  .email-input {
    display: flex;
    align-items: center;
    margin-top: 8px;
    input {
      background: $color-background-white1;
      padding: 8px;
      border-radius: 5px;
      border: none;
      width: 75%;
      margin-right: -8px;
      padding: 12px;
      color: $color-text-black1;
      height: 50px;
      border: none !important;
      outline: none !important;

      &::placeholder {
        color: #888888;
        opacity: 0.5;
      }
    }
    button {
      padding: 9px 8px;
      margin-left: -16px;
    }
  }

  .nav-menu-button {
    display: inline-block;
    text-align: center;
    // width: 118px;
    padding: 6px 24px;
    text-decoration: none;
    color: $color-text-white1;
    font-size: 16px;
    &.active {
      // button {
      color: $color-primary !important;
      // }
    }
    &:hover {
      // button {
      color: $color-primary !important;
      // }
    }
  }

  .nav-footer {
    display: block;
    text-align: left;
    width: 120px;
    text-decoration: none;
    color: $color-text-white1;
    cursor: pointer;
    transition-duration: 0.3s;
    &:hover {
      color: $color-primary;
    }
  }

  .link-footer {
    text-decoration: none;
    color: $color-text-white1;
    transition-duration: 0.3s;
    &:hover {
      color: $color-primary;
    }
  }

  .social-icons {
    .link-footer {
      display: inline-block;
      margin-top: 8px;
      margin-right: 16px;
    }
  }

  .icon-container {
    display: inline-flex;
    height: 48px;
    width: 48px;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    margin-left: 8px;
    border-radius: 5px;
    transform: rotate(45deg);
    background: $color-primary;

    .MuiSvgIcon-root {
      transform: rotate(-45deg);
    }
  }
}

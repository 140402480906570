@import "../../index.scss";

.header-component {
  position: fixed;
  width: 100%;
  padding: 16px 0px;
  height: 80px;
  z-index: 100;
  transition-duration: 0.4s;
  display: flex;
  align-items: center;
  background: $color-background-black1;

  .logo-image {
    height: 48px;
    margin-right: 16px;
    display: inline-block;
  }

  .nav-menu {
    a {
      display: inline-block;
      text-align: center;
      padding: 0px 16px;
      text-decoration: none;
      color: $color-text-white1;
      button {
        color: $color-text-white1;
        font-size: 16px;
        font-weight: 600 !important;
      }
      &.active {
        button {
          color: $color-primary !important;
        }
      }
    }
  }

  .nav-menu-button {
    display: inline-block;
    text-align: center;
    padding: 6px 24px;
    text-decoration: none;
    color: $color-text-white1;
    font-size: 16px;
    &.active {
      button {
        color: $color-primary !important;
      }
    }
  }

  .button-join-us {
    border-radius: 5px !important;
    margin-left: 32px;
  }

  .header-underline {
    transition-duration: 0.4s;
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    background-image: linear-gradient(transparent, transparent),
      linear-gradient(transparent, transparent),
      linear-gradient(to right, $color-primary, $color-accent);
    background-repeat: no-repeat;
    background-position: 0%;
    background-size: 100%;
  }
}

.nav-menu-mobile-container {
  width: 100%;
  background: $color-background-black1;

  hr {
    margin: 0px 12px;
  }

  .nav-menu-mobile {
    padding: 16px 0px;

    .nav-menu-button {
      text-align: left;
      width: 100%;
      text-decoration: none;
      margin-bottom: 16px;
      padding-right: 18px;
      justify-content: space-between;
      color: $color-text-white1;
      font-size: $font-size-lg;
    }
  }

  .button-join-us {
    border-radius: 5px !important;
    margin-top: 16px;
  }

  a {
    display: block;
    text-align: left;
    width: 100%;
    text-decoration: none;
    margin-bottom: 16px;
    button {
      width: 100%;
      justify-content: space-between;
      color: $color-text-white1;
    }
    &.active {
      button {
        width: 100%;
        justify-content: space-between;
        color: $color-primary !important;
      }
    }
  }

  .logo-image {
    height: 48px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 80px;
  }
}

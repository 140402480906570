@import "../../index.scss";

.section-media {
  padding: 0px 0px 32px 0px;

  @media (max-width: 991px) {
    // padding-bottom: 0px;
  }

  .media-card {
    display: block;
    width: 100%;
    padding: 24px 32px;
    margin-bottom: 16px;
    min-height: 240px;
    color: $color-text-white1;
    text-decoration: none;
    img {
      width: 100px;
      height: 40px;
      margin-right: 8px;
      object-fit: contain;
    }
    .card-title {
      font-family: "Chakra Petch" !important;
      font-size: 22px;
      display: inline-block;
      font-weight: 900 !important;
      vertical-align: -6px;
      color: $color-text-white1;
      margin-bottom: 24px;
    }
    p {
      margin-top: 8px;
      margin-bottom: 0px;
      color: $color-text-white2;
      border-left: 2px solid #ffffff;
      padding: 4px 16px;
    }
  }
}

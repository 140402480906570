@import "../../index.scss";

.section-operations {
  h1 {
    display: inline-block;
  }
  @media (max-width: 991px) {
    h1 {
      text-align: center;
    }
  }
  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    display: inline-block;
    max-width: 400px;
  }
  .container {
    display: block !important;
  }

  .heading-font {
    span {
      color: $color-accent;
    }
  }
}

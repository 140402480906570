@import "../../index.scss";

.privacy-component {
  color: $color-text-white1;
  .section-main {
    padding: 160px 0px;

    h2 {
      font-size: 20px !important;
      margin-bottom: 32px;
      margin-top: -16px;
      color: $color-text-black1;
    }

    .content {
      ol.lst-kix_list_7-0 {
        list-style-type: none;
      }
      .lst-kix_list_14-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_14-1, lower-latin) ") ";
      }
      .lst-kix_list_14-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_14-3, decimal) ") ";
      }
      .lst-kix_list_2-1 > li {
        counter-increment: lst-ctn-kix_list_2-1;
      }
      ol.lst-kix_list_15-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_15-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_15-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-0.start {
        counter-reset: lst-ctn-kix_list_9-0 0;
      }
      .lst-kix_list_14-0 > li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) ". ";
      }
      .lst-kix_list_14-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_14-4, lower-latin) ") ";
      }
      ol.lst-kix_list_15-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_15-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_15-4 {
        list-style-type: none;
      }
      .lst-kix_list_6-1 > li {
        counter-increment: lst-ctn-kix_list_6-1;
      }
      .lst-kix_list_14-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_14-5, lower-roman) ") ";
      }
      .lst-kix_list_14-7 > li:before {
        content: "" counter(lst-ctn-kix_list_14-7, lower-latin) ". ";
      }
      ol.lst-kix_list_15-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_15-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-4.start {
        counter-reset: lst-ctn-kix_list_13-4 0;
      }
      .lst-kix_list_14-6 > li:before {
        content: "" counter(lst-ctn-kix_list_14-6, decimal) ". ";
      }
      ol.lst-kix_list_15-1 {
        list-style-type: none;
      }
      .lst-kix_list_13-0 > li {
        counter-increment: lst-ctn-kix_list_13-0;
      }
      ol.lst-kix_list_7-4.start {
        counter-reset: lst-ctn-kix_list_7-4 0;
      }
      .lst-kix_list_17-0 > li {
        counter-increment: lst-ctn-kix_list_17-0;
      }
      .lst-kix_list_5-0 > li {
        counter-increment: lst-ctn-kix_list_5-0;
      }
      .lst-kix_list_9-0 > li {
        counter-increment: lst-ctn-kix_list_9-0;
      }
      ol.lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0;
      }
      ol.lst-kix_list_7-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-8.start {
        counter-reset: lst-ctn-kix_list_11-8 0;
      }
      ol.lst-kix_list_7-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-4 {
        list-style-type: none;
      }
      .lst-kix_list_14-2 > li:before {
        content: "" counter(lst-ctn-kix_list_14-2, lower-roman) ") ";
      }
      ol.lst-kix_list_5-3.start {
        counter-reset: lst-ctn-kix_list_5-3 0;
      }
      ol.lst-kix_list_12-0.start {
        counter-reset: lst-ctn-kix_list_12-0 0;
      }
      .lst-kix_list_4-3 > li {
        counter-increment: lst-ctn-kix_list_4-3;
      }
      ol.lst-kix_list_17-1.start {
        counter-reset: lst-ctn-kix_list_17-1 0;
      }
      ol.lst-kix_list_3-7.start {
        counter-reset: lst-ctn-kix_list_3-7 0;
      }
      .lst-kix_list_14-8 > li:before {
        content: "" counter(lst-ctn-kix_list_14-8, lower-roman) ". ";
      }
      ol.lst-kix_list_8-8.start {
        counter-reset: lst-ctn-kix_list_8-8 0;
      }
      .lst-kix_list_3-2 > li {
        counter-increment: lst-ctn-kix_list_3-2;
      }
      ol.lst-kix_list_15-5.start {
        counter-reset: lst-ctn-kix_list_15-5 0;
      }
      ol.lst-kix_list_10-4.start {
        counter-reset: lst-ctn-kix_list_10-4 0;
      }
      .lst-kix_list_7-2 > li {
        counter-increment: lst-ctn-kix_list_7-2;
      }
      .lst-kix_list_5-0 > li:before {
        content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
      }
      ol.lst-kix_list_6-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-1 {
        list-style-type: none;
      }
      .lst-kix_list_14-8 > li {
        counter-increment: lst-ctn-kix_list_14-8;
      }
      .lst-kix_list_5-4 > li {
        counter-increment: lst-ctn-kix_list_5-4;
      }
      ol.lst-kix_list_16-5 {
        list-style-type: none;
      }
      .lst-kix_list_1-4 > li {
        counter-increment: lst-ctn-kix_list_1-4;
      }
      ol.lst-kix_list_16-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_16-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0;
      }
      ol.lst-kix_list_16-8 {
        list-style-type: none;
      }
      .lst-kix_list_5-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-3, decimal) ") ";
      }
      ol.lst-kix_list_9-5.start {
        counter-reset: lst-ctn-kix_list_9-5 0;
      }
      ol.lst-kix_list_16-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_16-2 {
        list-style-type: none;
      }
      .lst-kix_list_5-2 > li:before {
        content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ") ";
      }
      ol.lst-kix_list_16-3 {
        list-style-type: none;
      }
      .lst-kix_list_8-3 > li {
        counter-increment: lst-ctn-kix_list_8-3;
      }
      ol.lst-kix_list_16-4 {
        list-style-type: none;
      }
      .lst-kix_list_5-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-1, lower-latin) ") ";
      }
      ol.lst-kix_list_16-0 {
        list-style-type: none;
      }
      .lst-kix_list_5-7 > li:before {
        content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". ";
      }
      .lst-kix_list_5-6 > li:before {
        content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
      }
      .lst-kix_list_5-8 > li:before {
        content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". ";
      }
      .lst-kix_list_9-4 > li {
        counter-increment: lst-ctn-kix_list_9-4;
      }
      ol.lst-kix_list_6-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-7 {
        list-style-type: none;
      }
      .lst-kix_list_5-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-4, lower-latin) ") ";
      }
      ol.lst-kix_list_6-8 {
        list-style-type: none;
      }
      .lst-kix_list_5-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-5, lower-roman) ") ";
      }
      ol.lst-kix_list_6-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_16-4.start {
        counter-reset: lst-ctn-kix_list_16-4 0;
      }
      ol.lst-kix_list_6-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-1.start {
        counter-reset: lst-ctn-kix_list_14-1 0;
      }
      ol.lst-kix_list_12-5.start {
        counter-reset: lst-ctn-kix_list_12-5 0;
      }
      .lst-kix_list_6-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-1, lower-latin) ") ";
      }
      .lst-kix_list_6-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-3, decimal) ") ";
      }
      .lst-kix_list_6-5 > li {
        counter-increment: lst-ctn-kix_list_6-5;
      }
      .lst-kix_list_6-8 > li {
        counter-increment: lst-ctn-kix_list_6-8;
      }
      .lst-kix_list_6-0 > li:before {
        content: "" counter(lst-ctn-kix_list_6-0, decimal) ". ";
      }
      .lst-kix_list_6-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-4, lower-latin) ") ";
      }
      ol.lst-kix_list_14-8.start {
        counter-reset: lst-ctn-kix_list_14-8 0;
      }
      .lst-kix_list_3-6 > li {
        counter-increment: lst-ctn-kix_list_3-6;
      }
      .lst-kix_list_6-2 > li:before {
        content: "" counter(lst-ctn-kix_list_6-2, lower-roman) ") ";
      }
      ol.lst-kix_list_15-0.start {
        counter-reset: lst-ctn-kix_list_15-0 0;
      }
      .lst-kix_list_2-5 > li {
        counter-increment: lst-ctn-kix_list_2-5;
      }
      .lst-kix_list_2-8 > li {
        counter-increment: lst-ctn-kix_list_2-8;
      }
      ol.lst-kix_list_3-2.start {
        counter-reset: lst-ctn-kix_list_3-2 0;
      }
      .lst-kix_list_6-8 > li:before {
        content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ". ";
      }
      .lst-kix_list_6-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-5, lower-roman) ") ";
      }
      .lst-kix_list_6-7 > li:before {
        content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ". ";
      }
      .lst-kix_list_6-6 > li:before {
        content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
      }
      ol.lst-kix_list_17-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-6.start {
        counter-reset: lst-ctn-kix_list_10-6 0;
      }
      .lst-kix_list_7-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_7-4, lower-latin) ") ";
      }
      .lst-kix_list_7-6 > li:before {
        content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
      }
      ol.lst-kix_list_17-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_17-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-2.start {
        counter-reset: lst-ctn-kix_list_6-2 0;
      }
      .lst-kix_list_15-5 > li {
        counter-increment: lst-ctn-kix_list_15-5;
      }
      ol.lst-kix_list_17-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_17-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_17-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_17-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_17-2 {
        list-style-type: none;
      }
      .lst-kix_list_7-2 > li:before {
        content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ") ";
      }
      ol.lst-kix_list_17-3 {
        list-style-type: none;
      }
      .lst-kix_list_7-6 > li {
        counter-increment: lst-ctn-kix_list_7-6;
      }
      .lst-kix_list_8-6 > li {
        counter-increment: lst-ctn-kix_list_8-6;
      }
      .lst-kix_list_12-6 > li {
        counter-increment: lst-ctn-kix_list_12-6;
      }
      ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0;
      }
      ol.lst-kix_list_9-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-8 {
        list-style-type: none;
      }
      .lst-kix_list_13-7 > li:before {
        content: "" counter(lst-ctn-kix_list_13-7, lower-latin) ". ";
      }
      ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0;
      }
      ol.lst-kix_list_9-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-4 {
        list-style-type: none;
      }
      .lst-kix_list_5-7 > li {
        counter-increment: lst-ctn-kix_list_5-7;
      }
      ol.lst-kix_list_9-5 {
        list-style-type: none;
      }
      .lst-kix_list_7-8 > li:before {
        content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". ";
      }
      ol.lst-kix_list_9-6 {
        list-style-type: none;
      }
      .lst-kix_list_15-6 > li {
        counter-increment: lst-ctn-kix_list_15-6;
      }
      .lst-kix_list_4-7 > li {
        counter-increment: lst-ctn-kix_list_4-7;
      }
      ol.lst-kix_list_9-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0;
      }
      .lst-kix_list_15-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_15-5, lower-roman) ") ";
      }
      .lst-kix_list_9-8 > li {
        counter-increment: lst-ctn-kix_list_9-8;
      }
      .lst-kix_list_13-4 > li {
        counter-increment: lst-ctn-kix_list_13-4;
      }
      .lst-kix_list_4-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_4-1, lower-latin) ") ";
      }
      .lst-kix_list_15-7 > li:before {
        content: "" counter(lst-ctn-kix_list_15-7, lower-latin) ". ";
      }
      .lst-kix_list_17-7 > li {
        counter-increment: lst-ctn-kix_list_17-7;
      }
      .lst-kix_list_4-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_4-3, decimal) ") ";
      }
      .lst-kix_list_4-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_4-5, lower-roman) ") ";
      }
      .lst-kix_list_1-8 > li {
        counter-increment: lst-ctn-kix_list_1-8;
      }
      .lst-kix_list_10-5 > li {
        counter-increment: lst-ctn-kix_list_10-5;
      }
      .lst-kix_list_15-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_15-1, lower-latin) ") ";
      }
      ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0;
      }
      .lst-kix_list_3-5 > li {
        counter-increment: lst-ctn-kix_list_3-5;
      }
      ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0;
      }
      .lst-kix_list_15-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_15-3, decimal) ") ";
      }
      ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0;
      }
      .lst-kix_list_16-2 > li {
        counter-increment: lst-ctn-kix_list_16-2;
      }
      .lst-kix_list_6-4 > li {
        counter-increment: lst-ctn-kix_list_6-4;
      }
      ol.lst-kix_list_9-2.start {
        counter-reset: lst-ctn-kix_list_9-2 0;
      }
      ol.lst-kix_list_16-7.start {
        counter-reset: lst-ctn-kix_list_16-7 0;
      }
      .lst-kix_list_9-3 > li {
        counter-increment: lst-ctn-kix_list_9-3;
      }
      .lst-kix_list_11-2 > li {
        counter-increment: lst-ctn-kix_list_11-2;
      }
      ol.lst-kix_list_15-2.start {
        counter-reset: lst-ctn-kix_list_15-2 0;
      }
      ol.lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0;
      }
      ol.lst-kix_list_8-8 {
        list-style-type: none;
      }
      .lst-kix_list_12-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_12-3, decimal) ") ";
      }
      ol.lst-kix_list_7-6.start {
        counter-reset: lst-ctn-kix_list_7-6 0;
      }
      ol.lst-kix_list_8-4 {
        list-style-type: none;
      }
      .lst-kix_list_12-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_12-1, lower-latin) ") ";
      }
      ol.lst-kix_list_8-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-0 {
        list-style-type: none;
      }
      .lst-kix_list_16-3 > li {
        counter-increment: lst-ctn-kix_list_16-3;
      }
      ol.lst-kix_list_8-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-2 {
        list-style-type: none;
      }
      .lst-kix_list_13-3 > li {
        counter-increment: lst-ctn-kix_list_13-3;
      }
      ol.lst-kix_list_13-6.start {
        counter-reset: lst-ctn-kix_list_13-6 0;
      }
      ol.lst-kix_list_8-3 {
        list-style-type: none;
      }
      .lst-kix_list_10-4 > li {
        counter-increment: lst-ctn-kix_list_10-4;
      }
      ol.lst-kix_list_15-3.start {
        counter-reset: lst-ctn-kix_list_15-3 0;
      }
      .lst-kix_list_14-1 > li {
        counter-increment: lst-ctn-kix_list_14-1;
      }
      .lst-kix_list_13-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_13-3, decimal) ") ";
      }
      ol.lst-kix_list_5-8.start {
        counter-reset: lst-ctn-kix_list_5-8 0;
      }
      .lst-kix_list_1-3 > li {
        counter-increment: lst-ctn-kix_list_1-3;
      }
      li.li-bullet-4:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt;
      }
      .lst-kix_list_13-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_13-5, lower-roman) ") ";
      }
      .lst-kix_list_12-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_12-5, lower-roman) ") ";
      }
      ol.lst-kix_list_13-7.start {
        counter-reset: lst-ctn-kix_list_13-7 0;
      }
      ol.lst-kix_list_12-2.start {
        counter-reset: lst-ctn-kix_list_12-2 0;
      }
      .lst-kix_list_12-7 > li:before {
        content: "" counter(lst-ctn-kix_list_12-7, lower-latin) ". ";
      }
      ol.lst-kix_list_6-0.start {
        counter-reset: lst-ctn-kix_list_6-0 0;
      }
      .lst-kix_list_4-2 > li {
        counter-increment: lst-ctn-kix_list_4-2;
      }
      .lst-kix_list_13-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_13-1, lower-latin) ") ";
      }
      ol.lst-kix_list_3-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-4.start {
        counter-reset: lst-ctn-kix_list_3-4 0;
      }
      .lst-kix_list_5-1 > li {
        counter-increment: lst-ctn-kix_list_5-1;
      }
      ol.lst-kix_list_3-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-8 {
        list-style-type: none;
      }
      .lst-kix_list_1-1 > li {
        counter-increment: lst-ctn-kix_list_1-1;
      }
      .lst-kix_list_7-1 > li {
        counter-increment: lst-ctn-kix_list_7-1;
      }
      ol.lst-kix_list_11-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0;
      }
      .lst-kix_list_3-0 > li:before {
        content: "" counter(lst-ctn-kix_list_3-0, decimal) ". ";
      }
      ol.lst-kix_list_11-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-1.start {
        counter-reset: lst-ctn-kix_list_13-1 0;
      }
      ol.lst-kix_list_11-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-1 {
        list-style-type: none;
      }
      .lst-kix_list_16-0 > li {
        counter-increment: lst-ctn-kix_list_16-0;
      }
      .lst-kix_list_4-0 > li {
        counter-increment: lst-ctn-kix_list_4-0;
      }
      .lst-kix_list_8-0 > li {
        counter-increment: lst-ctn-kix_list_8-0;
      }
      .lst-kix_list_10-0 > li {
        counter-increment: lst-ctn-kix_list_10-0;
      }
      .lst-kix_list_3-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-4, lower-latin) ") ";
      }
      .lst-kix_list_3-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-3, decimal) ") ";
      }
      ol.lst-kix_list_3-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-6 {
        list-style-type: none;
      }
      .lst-kix_list_8-0 > li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) ". ";
      }
      ol.lst-kix_list_3-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-8 {
        list-style-type: none;
      }
      .lst-kix_list_8-7 > li:before {
        content: "" counter(lst-ctn-kix_list_8-7, lower-latin) ". ";
      }
      .lst-kix_list_3-8 > li:before {
        content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
      }
      ol.lst-kix_list_10-7.start {
        counter-reset: lst-ctn-kix_list_10-7 0;
      }
      .lst-kix_list_13-1 > li {
        counter-increment: lst-ctn-kix_list_13-1;
      }
      ol.lst-kix_list_15-8.start {
        counter-reset: lst-ctn-kix_list_15-8 0;
      }
      .lst-kix_list_8-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_8-3, decimal) ") ";
      }
      .lst-kix_list_3-7 > li:before {
        content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
      }
      .lst-kix_list_8-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_8-4, lower-latin) ") ";
      }
      .lst-kix_list_10-2 > li {
        counter-increment: lst-ctn-kix_list_10-2;
      }
      ol.lst-kix_list_8-5.start {
        counter-reset: lst-ctn-kix_list_8-5 0;
      }
      .lst-kix_list_17-1 > li {
        counter-increment: lst-ctn-kix_list_17-1;
      }
      .lst-kix_list_11-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_11-1, lower-latin) ") ";
      }
      .lst-kix_list_11-0 > li:before {
        content: "" counter(lst-ctn-kix_list_11-0, decimal) ". ";
      }
      ol.lst-kix_list_9-3.start {
        counter-reset: lst-ctn-kix_list_9-3 0;
      }
      .lst-kix_list_8-8 > li:before {
        content: "" counter(lst-ctn-kix_list_8-8, lower-roman) ". ";
      }
      ol.lst-kix_list_2-2 {
        list-style-type: none;
      }
      .lst-kix_list_16-8 > li:before {
        content: "" counter(lst-ctn-kix_list_16-8, lower-roman) ". ";
      }
      ol.lst-kix_list_2-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-4 {
        list-style-type: none;
      }
      .lst-kix_list_16-7 > li:before {
        content: "" counter(lst-ctn-kix_list_16-7, lower-latin) ". ";
      }
      ol.lst-kix_list_7-2.start {
        counter-reset: lst-ctn-kix_list_7-2 0;
      }
      ol.lst-kix_list_2-5 {
        list-style-type: none;
      }
      .lst-kix_list_17-8 > li {
        counter-increment: lst-ctn-kix_list_17-8;
      }
      ol.lst-kix_list_2-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-1 {
        list-style-type: none;
      }
      .lst-kix_list_4-8 > li:before {
        content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". ";
      }
      ol.lst-kix_list_12-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-6 {
        list-style-type: none;
      }
      .lst-kix_list_4-7 > li:before {
        content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". ";
      }
      ol.lst-kix_list_12-7 {
        list-style-type: none;
      }
      .lst-kix_list_14-2 > li {
        counter-increment: lst-ctn-kix_list_14-2;
      }
      .lst-kix_list_17-0 > li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) ". ";
      }
      ol.lst-kix_list_12-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-4 {
        list-style-type: none;
      }
      .lst-kix_list_16-0 > li:before {
        content: "" counter(lst-ctn-kix_list_16-0, decimal) ". ";
      }
      ol.lst-kix_list_12-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0;
      }
      .lst-kix_list_8-4 > li {
        counter-increment: lst-ctn-kix_list_8-4;
      }
      .lst-kix_list_16-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_16-4, lower-latin) ") ";
      }
      ol.lst-kix_list_3-3.start {
        counter-reset: lst-ctn-kix_list_3-3 0;
      }
      ol.lst-kix_list_10-8.start {
        counter-reset: lst-ctn-kix_list_10-8 0;
      }
      .lst-kix_list_16-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_16-3, decimal) ") ";
      }
      ol.lst-kix_list_2-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-8 {
        list-style-type: none;
      }
      .lst-kix_list_11-3 > li {
        counter-increment: lst-ctn-kix_list_11-3;
      }
      ol.lst-kix_list_7-1.start {
        counter-reset: lst-ctn-kix_list_7-1 0;
      }
      ol.lst-kix_list_8-6.start {
        counter-reset: lst-ctn-kix_list_8-6 0;
      }
      .lst-kix_list_17-7 > li:before {
        content: "" counter(lst-ctn-kix_list_17-7, lower-latin) ". ";
      }
      .lst-kix_list_16-7 > li {
        counter-increment: lst-ctn-kix_list_16-7;
      }
      .lst-kix_list_17-8 > li:before {
        content: "" counter(lst-ctn-kix_list_17-8, lower-roman) ". ";
      }
      .lst-kix_list_17-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_17-3, decimal) ") ";
      }
      .lst-kix_list_17-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_17-4, lower-latin) ") ";
      }
      ol.lst-kix_list_8-0.start {
        counter-reset: lst-ctn-kix_list_8-0 0;
      }
      .lst-kix_list_7-0 > li:before {
        content: "" counter(lst-ctn-kix_list_7-0, decimal) ". ";
      }
      .lst-kix_list_13-8 > li {
        counter-increment: lst-ctn-kix_list_13-8;
      }
      .lst-kix_list_2-2 > li {
        counter-increment: lst-ctn-kix_list_2-2;
      }
      ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0;
      }
      .lst-kix_list_16-5 > li {
        counter-increment: lst-ctn-kix_list_16-5;
      }
      ol.lst-kix_list_5-0 {
        list-style-type: none;
      }
      .lst-kix_list_3-7 > li {
        counter-increment: lst-ctn-kix_list_3-7;
      }
      ol.lst-kix_list_5-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-7.start {
        counter-reset: lst-ctn-kix_list_9-7 0;
      }
      ol.lst-kix_list_5-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-8 {
        list-style-type: none;
      }
      .lst-kix_list_2-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-4, lower-latin) ") ";
      }
      .lst-kix_list_2-8 > li:before {
        content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
      }
      ol.lst-kix_list_13-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-1 {
        list-style-type: none;
      }
      .lst-kix_list_6-6 > li {
        counter-increment: lst-ctn-kix_list_6-6;
      }
      ol.lst-kix_list_13-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_15-7.start {
        counter-reset: lst-ctn-kix_list_15-7 0;
      }
      .lst-kix_list_7-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_7-3, decimal) ") ";
      }
      ol.lst-kix_list_13-3 {
        list-style-type: none;
      }
      .lst-kix_list_10-0 > li:before {
        content: "" counter(lst-ctn-kix_list_10-0, decimal) ". ";
      }
      .lst-kix_list_9-7 > li {
        counter-increment: lst-ctn-kix_list_9-7;
      }
      .lst-kix_list_13-6 > li {
        counter-increment: lst-ctn-kix_list_13-6;
      }
      .lst-kix_list_13-8 > li:before {
        content: "" counter(lst-ctn-kix_list_13-8, lower-roman) ". ";
      }
      ol.lst-kix_list_14-6.start {
        counter-reset: lst-ctn-kix_list_14-6 0;
      }
      ol.lst-kix_list_5-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_5-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_5-3 {
        list-style-type: none;
      }
      .lst-kix_list_8-7 > li {
        counter-increment: lst-ctn-kix_list_8-7;
      }
      ol.lst-kix_list_5-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-8.start {
        counter-reset: lst-ctn-kix_list_3-8 0;
      }
      ol.lst-kix_list_5-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_5-6 {
        list-style-type: none;
      }
      .lst-kix_list_7-7 > li:before {
        content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". ";
      }
      ol.lst-kix_list_8-1.start {
        counter-reset: lst-ctn-kix_list_8-1 0;
      }
      .lst-kix_list_15-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_15-4, lower-latin) ") ";
      }
      .lst-kix_list_9-5 > li {
        counter-increment: lst-ctn-kix_list_9-5;
      }
      .lst-kix_list_5-8 > li {
        counter-increment: lst-ctn-kix_list_5-8;
      }
      .lst-kix_list_10-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_10-4, lower-latin) ") ";
      }
      .lst-kix_list_10-8 > li:before {
        content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". ";
      }
      .lst-kix_list_4-0 > li:before {
        content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
      }
      .lst-kix_list_15-0 > li:before {
        content: "" counter(lst-ctn-kix_list_15-0, decimal) ". ";
      }
      .lst-kix_list_15-8 > li:before {
        content: "" counter(lst-ctn-kix_list_15-8, lower-roman) ". ";
      }
      li.li-bullet-3:before {
        margin-left: -17.6pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 17.6pt;
      }
      ol.lst-kix_list_14-3.start {
        counter-reset: lst-ctn-kix_list_14-3 0;
      }
      .lst-kix_list_15-7 > li {
        counter-increment: lst-ctn-kix_list_15-7;
      }
      .lst-kix_list_4-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_4-4, lower-latin) ") ";
      }
      ol.lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0;
      }
      .lst-kix_list_9-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-3, decimal) ") ";
      }
      ol.lst-kix_list_7-0.start {
        counter-reset: lst-ctn-kix_list_7-0 5;
      }
      .lst-kix_list_12-8 > li {
        counter-increment: lst-ctn-kix_list_12-8;
      }
      ol.lst-kix_list_13-2.start {
        counter-reset: lst-ctn-kix_list_13-2 0;
      }
      ol.lst-kix_list_4-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-4.start {
        counter-reset: lst-ctn-kix_list_14-4 0;
      }
      ol.lst-kix_list_14-8 {
        list-style-type: none;
      }
      .lst-kix_list_9-7 > li:before {
        content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". ";
      }
      .lst-kix_list_2-4 > li {
        counter-increment: lst-ctn-kix_list_2-4;
      }
      ol.lst-kix_list_14-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-6.start {
        counter-reset: lst-ctn-kix_list_3-6 0;
      }
      ol.lst-kix_list_14-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-6 {
        list-style-type: none;
      }
      .lst-kix_list_11-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_11-4, lower-latin) ") ";
      }
      .lst-kix_list_15-2 > li {
        counter-increment: lst-ctn-kix_list_15-2;
      }
      ol.lst-kix_list_14-0 {
        list-style-type: none;
      }
      .lst-kix_list_12-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_12-4, lower-latin) ") ";
      }
      ol.lst-kix_list_14-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-2 {
        list-style-type: none;
      }
      .lst-kix_list_5-3 > li {
        counter-increment: lst-ctn-kix_list_5-3;
      }
      ol.lst-kix_list_4-8 {
        list-style-type: none;
      }
      .lst-kix_list_7-4 > li {
        counter-increment: lst-ctn-kix_list_7-4;
      }
      .lst-kix_list_1-0 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
      }
      ol.lst-kix_list_4-4 {
        list-style-type: none;
      }
      .lst-kix_list_11-8 > li:before {
        content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". ";
      }
      .lst-kix_list_12-3 > li {
        counter-increment: lst-ctn-kix_list_12-3;
      }
      ol.lst-kix_list_4-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0;
      }
      ol.lst-kix_list_4-6 {
        list-style-type: none;
      }
      .lst-kix_list_12-0 > li:before {
        content: "" counter(lst-ctn-kix_list_12-0, decimal) ". ";
      }
      .lst-kix_list_17-3 > li {
        counter-increment: lst-ctn-kix_list_17-3;
      }
      ol.lst-kix_list_4-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-4.start {
        counter-reset: lst-ctn-kix_list_8-4 0;
      }
      .lst-kix_list_1-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_1-4, lower-latin) ") ";
      }
      .lst-kix_list_13-0 > li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) ". ";
      }
      ol.lst-kix_list_3-5.start {
        counter-reset: lst-ctn-kix_list_3-5 0;
      }
      .lst-kix_list_1-6 > li {
        counter-increment: lst-ctn-kix_list_1-6;
      }
      ol.lst-kix_list_13-0.start {
        counter-reset: lst-ctn-kix_list_13-0 0;
      }
      .lst-kix_list_14-4 > li {
        counter-increment: lst-ctn-kix_list_14-4;
      }
      .lst-kix_list_13-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_13-4, lower-latin) ") ";
      }
      .lst-kix_list_10-7 > li {
        counter-increment: lst-ctn-kix_list_10-7;
      }
      .lst-kix_list_2-0 > li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
      }
      ol.lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0;
      }
      ol.lst-kix_list_8-3.start {
        counter-reset: lst-ctn-kix_list_8-3 0;
      }
      .lst-kix_list_11-5 > li {
        counter-increment: lst-ctn-kix_list_11-5;
      }
      .lst-kix_list_4-5 > li {
        counter-increment: lst-ctn-kix_list_4-5;
      }
      ol.lst-kix_list_14-5.start {
        counter-reset: lst-ctn-kix_list_14-5 0;
      }
      ol.lst-kix_list_9-8.start {
        counter-reset: lst-ctn-kix_list_9-8 0;
      }
      .lst-kix_list_1-8 > li:before {
        content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
      }
      .lst-kix_list_12-8 > li:before {
        content: "" counter(lst-ctn-kix_list_12-8, lower-roman) ". ";
      }
      .lst-kix_list_8-2 > li {
        counter-increment: lst-ctn-kix_list_8-2;
      }
      .lst-kix_list_4-1 > li {
        counter-increment: lst-ctn-kix_list_4-1;
      }
      ol.lst-kix_list_17-7.start {
        counter-reset: lst-ctn-kix_list_17-7 0;
      }
      ol.lst-kix_list_12-6.start {
        counter-reset: lst-ctn-kix_list_12-6 0;
      }
      .lst-kix_list_8-1 > li {
        counter-increment: lst-ctn-kix_list_8-1;
      }
      ol.lst-kix_list_8-2.start {
        counter-reset: lst-ctn-kix_list_8-2 0;
      }
      ol.lst-kix_list_3-1.start {
        counter-reset: lst-ctn-kix_list_3-1 0;
      }
      .lst-kix_list_15-0 > li {
        counter-increment: lst-ctn-kix_list_15-0;
      }
      ol.lst-kix_list_6-6.start {
        counter-reset: lst-ctn-kix_list_6-6 0;
      }
      .lst-kix_list_7-0 > li {
        counter-increment: lst-ctn-kix_list_7-0;
      }
      .lst-kix_list_11-0 > li {
        counter-increment: lst-ctn-kix_list_11-0;
      }
      ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0;
      }
      ol.lst-kix_list_9-6.start {
        counter-reset: lst-ctn-kix_list_9-6 0;
      }
      ol.lst-kix_list_16-3.start {
        counter-reset: lst-ctn-kix_list_16-3 0;
      }
      .lst-kix_list_2-3 > li {
        counter-increment: lst-ctn-kix_list_2-3;
      }
      ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0;
      }
      .lst-kix_list_1-2 > li {
        counter-increment: lst-ctn-kix_list_1-2;
      }
      ol.lst-kix_list_14-7.start {
        counter-reset: lst-ctn-kix_list_14-7 0;
      }
      ol.lst-kix_list_11-2.start {
        counter-reset: lst-ctn-kix_list_11-2 0;
      }
      .lst-kix_list_5-2 > li {
        counter-increment: lst-ctn-kix_list_5-2;
      }
      .lst-kix_list_9-2 > li {
        counter-increment: lst-ctn-kix_list_9-2;
      }
      ol.lst-kix_list_8-7.start {
        counter-reset: lst-ctn-kix_list_8-7 0;
      }
      .lst-kix_list_17-2 > li {
        counter-increment: lst-ctn-kix_list_17-2;
      }
      ol.lst-kix_list_17-2.start {
        counter-reset: lst-ctn-kix_list_17-2 0;
      }
      .lst-kix_list_13-2 > li {
        counter-increment: lst-ctn-kix_list_13-2;
      }
      li.li-bullet-6:before {
        margin-left: -17.7pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 17.7pt;
      }
      .lst-kix_list_14-3 > li {
        counter-increment: lst-ctn-kix_list_14-3;
      }
      ol.lst-kix_list_15-6.start {
        counter-reset: lst-ctn-kix_list_15-6 0;
      }
      .lst-kix_list_10-3 > li {
        counter-increment: lst-ctn-kix_list_10-3;
      }
      .lst-kix_list_12-1 > li {
        counter-increment: lst-ctn-kix_list_12-1;
      }
      ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0;
      }
      ol.lst-kix_list_13-3.start {
        counter-reset: lst-ctn-kix_list_13-3 0;
      }
      .lst-kix_list_3-0 > li {
        counter-increment: lst-ctn-kix_list_3-0;
      }
      ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0;
      }
      li.li-bullet-2:before {
        margin-left: -17.7pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 17.7pt;
      }
      ol.lst-kix_list_11-7.start {
        counter-reset: lst-ctn-kix_list_11-7 0;
      }
      ol.lst-kix_list_14-2.start {
        counter-reset: lst-ctn-kix_list_14-2 0;
      }
      .lst-kix_list_16-1 > li {
        counter-increment: lst-ctn-kix_list_16-1;
      }
      ol.lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0;
      }
      ol.lst-kix_list_1-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-4 {
        list-style-type: none;
      }
      .lst-kix_list_2-7 > li:before {
        content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
      }
      .lst-kix_list_2-7 > li {
        counter-increment: lst-ctn-kix_list_2-7;
      }
      ol.lst-kix_list_1-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-0 {
        list-style-type: none;
      }
      .lst-kix_list_2-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-5, lower-roman) ") ";
      }
      ol.lst-kix_list_1-1 {
        list-style-type: none;
      }
      .lst-kix_list_17-5 > li {
        counter-increment: lst-ctn-kix_list_17-5;
      }
      ol.lst-kix_list_1-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_17-0.start {
        counter-reset: lst-ctn-kix_list_17-0 11;
      }
      ol.lst-kix_list_10-3.start {
        counter-reset: lst-ctn-kix_list_10-3 0;
      }
      ol.lst-kix_list_9-4.start {
        counter-reset: lst-ctn-kix_list_9-4 0;
      }
      .lst-kix_list_14-6 > li {
        counter-increment: lst-ctn-kix_list_14-6;
      }
      .lst-kix_list_10-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_10-1, lower-latin) ") ";
      }
      .lst-kix_list_17-6 > li {
        counter-increment: lst-ctn-kix_list_17-6;
      }
      .lst-kix_list_7-7 > li {
        counter-increment: lst-ctn-kix_list_7-7;
      }
      ol.lst-kix_list_15-1.start {
        counter-reset: lst-ctn-kix_list_15-1 0;
      }
      ol.lst-kix_list_15-4.start {
        counter-reset: lst-ctn-kix_list_15-4 0;
      }
      ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0;
      }
      ol.lst-kix_list_1-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-8 {
        list-style-type: none;
      }
      .lst-kix_list_10-7 > li:before {
        content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". ";
      }
      .lst-kix_list_7-8 > li {
        counter-increment: lst-ctn-kix_list_7-8;
      }
      .lst-kix_list_10-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_10-5, lower-roman) ") ";
      }
      ol.lst-kix_list_13-5.start {
        counter-reset: lst-ctn-kix_list_13-5 0;
      }
      li.li-bullet-1:before {
        margin-left: -17.7pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 17.7pt;
      }
      .lst-kix_list_10-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_10-3, decimal) ") ";
      }
      .lst-kix_list_15-4 > li {
        counter-increment: lst-ctn-kix_list_15-4;
      }
      .lst-kix_list_2-6 > li {
        counter-increment: lst-ctn-kix_list_2-6;
      }
      ol.lst-kix_list_7-3.start {
        counter-reset: lst-ctn-kix_list_7-3 0;
      }
      ol.lst-kix_list_13-8.start {
        counter-reset: lst-ctn-kix_list_13-8 0;
      }
      .lst-kix_list_11-7 > li {
        counter-increment: lst-ctn-kix_list_11-7;
      }
      .lst-kix_list_9-2 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-2, lower-roman) ") ";
      }
      ol.lst-kix_list_5-7.start {
        counter-reset: lst-ctn-kix_list_5-7 0;
      }
      ol.lst-kix_list_14-0.start {
        counter-reset: lst-ctn-kix_list_14-0 10;
      }
      .lst-kix_list_12-5 > li {
        counter-increment: lst-ctn-kix_list_12-5;
      }
      .lst-kix_list_5-5 > li {
        counter-increment: lst-ctn-kix_list_5-5;
      }
      .lst-kix_list_9-0 > li:before {
        content: "" counter(lst-ctn-kix_list_9-0, decimal) ". ";
      }
      .lst-kix_list_3-4 > li {
        counter-increment: lst-ctn-kix_list_3-4;
      }
      .lst-kix_list_16-8 > li {
        counter-increment: lst-ctn-kix_list_16-8;
      }
      ol.lst-kix_list_10-7 {
        list-style-type: none;
      }
      .lst-kix_list_9-6 > li:before {
        content: "" counter(lst-ctn-kix_list_9-6, decimal) ". ";
      }
      ol.lst-kix_list_10-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-3 {
        list-style-type: none;
      }
      .lst-kix_list_9-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-4, lower-latin) ") ";
      }
      ol.lst-kix_list_10-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-5 {
        list-style-type: none;
      }
      .lst-kix_list_11-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_11-3, decimal) ") ";
      }
      ol.lst-kix_list_10-6 {
        list-style-type: none;
      }
      .lst-kix_list_6-3 > li {
        counter-increment: lst-ctn-kix_list_6-3;
      }
      ol.lst-kix_list_10-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0;
      }
      ol.lst-kix_list_10-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-1.start {
        counter-reset: lst-ctn-kix_list_12-1 0;
      }
      ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0;
      }
      .lst-kix_list_11-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_11-5, lower-roman) ") ";
      }
      ol.lst-kix_list_6-1.start {
        counter-reset: lst-ctn-kix_list_6-1 0;
      }
      .lst-kix_list_9-8 > li:before {
        content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". ";
      }
      .lst-kix_list_1-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_1-1, lower-latin) ") ";
      }
      .lst-kix_list_11-7 > li:before {
        content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". ";
      }
      .lst-kix_list_8-5 > li {
        counter-increment: lst-ctn-kix_list_8-5;
      }
      .lst-kix_list_1-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_1-3, decimal) ") ";
      }
      ol.lst-kix_list_16-8.start {
        counter-reset: lst-ctn-kix_list_16-8 0;
      }
      ol.lst-kix_list_10-5.start {
        counter-reset: lst-ctn-kix_list_10-5 0;
      }
      .lst-kix_list_4-8 > li {
        counter-increment: lst-ctn-kix_list_4-8;
      }
      .lst-kix_list_1-7 > li:before {
        content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
      }
      ol.lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0;
      }
      .lst-kix_list_1-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_1-5, lower-roman) ") ";
      }
      ol.lst-kix_list_9-1.start {
        counter-reset: lst-ctn-kix_list_9-1 0;
      }
      .lst-kix_list_14-7 > li {
        counter-increment: lst-ctn-kix_list_14-7;
      }
      .lst-kix_list_5-6 > li {
        counter-increment: lst-ctn-kix_list_5-6;
      }
      ol.lst-kix_list_7-5.start {
        counter-reset: lst-ctn-kix_list_7-5 0;
      }
      .lst-kix_list_2-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-1, lower-latin) ") ";
      }
      .lst-kix_list_2-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-3, decimal) ") ";
      }
      .lst-kix_list_11-8 > li {
        counter-increment: lst-ctn-kix_list_11-8;
      }
      .lst-kix_list_3-1 > li {
        counter-increment: lst-ctn-kix_list_3-1;
      }
      .lst-kix_list_9-1 > li {
        counter-increment: lst-ctn-kix_list_9-1;
      }
      ol.lst-kix_list_7-7.start {
        counter-reset: lst-ctn-kix_list_7-7 0;
      }
      .lst-kix_list_3-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-1, lower-latin) ") ";
      }
      .lst-kix_list_3-2 > li:before {
        content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ") ";
      }
      .lst-kix_list_14-0 > li {
        counter-increment: lst-ctn-kix_list_14-0;
      }
      .lst-kix_list_8-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_8-1, lower-latin) ") ";
      }
      ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0;
      }
      ol.lst-kix_list_17-4.start {
        counter-reset: lst-ctn-kix_list_17-4 0;
      }
      .lst-kix_list_8-2 > li:before {
        content: "(" counter(lst-ctn-kix_list_8-2, lower-roman) ") ";
      }
      .lst-kix_list_6-0 > li {
        counter-increment: lst-ctn-kix_list_6-0;
      }
      .lst-kix_list_3-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-5, lower-roman) ") ";
      }
      .lst-kix_list_12-0 > li {
        counter-increment: lst-ctn-kix_list_12-0;
      }
      ol.lst-kix_list_12-3.start {
        counter-reset: lst-ctn-kix_list_12-3 0;
      }
      ol.lst-kix_list_11-5.start {
        counter-reset: lst-ctn-kix_list_11-5 0;
      }
      .lst-kix_list_8-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_8-5, lower-roman) ") ";
      }
      .lst-kix_list_11-1 > li {
        counter-increment: lst-ctn-kix_list_11-1;
      }
      .lst-kix_list_8-6 > li:before {
        content: "" counter(lst-ctn-kix_list_8-6, decimal) ". ";
      }
      .lst-kix_list_2-0 > li {
        counter-increment: lst-ctn-kix_list_2-0;
      }
      .lst-kix_list_15-1 > li {
        counter-increment: lst-ctn-kix_list_15-1;
      }
      .lst-kix_list_3-6 > li:before {
        content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
      }
      ol.lst-kix_list_16-6.start {
        counter-reset: lst-ctn-kix_list_16-6 0;
      }
      ol.lst-kix_list_5-0.start {
        counter-reset: lst-ctn-kix_list_5-0 0;
      }
      .lst-kix_list_11-2 > li:before {
        content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ") ";
      }
      ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0;
      }
      ol.lst-kix_list_16-0.start {
        counter-reset: lst-ctn-kix_list_16-0 0;
      }
      ol.lst-kix_list_11-6.start {
        counter-reset: lst-ctn-kix_list_11-6 0;
      }
      ol.lst-kix_list_12-4.start {
        counter-reset: lst-ctn-kix_list_12-4 0;
      }
      .lst-kix_list_16-6 > li:before {
        content: "" counter(lst-ctn-kix_list_16-6, decimal) ". ";
      }
      ol.lst-kix_list_10-1.start {
        counter-reset: lst-ctn-kix_list_10-1 0;
      }
      .lst-kix_list_4-4 > li {
        counter-increment: lst-ctn-kix_list_4-4;
      }
      ol.lst-kix_list_6-4.start {
        counter-reset: lst-ctn-kix_list_6-4 0;
      }
      .lst-kix_list_17-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_17-1, lower-latin) ") ";
      }
      ol.lst-kix_list_5-6.start {
        counter-reset: lst-ctn-kix_list_5-6 0;
      }
      .lst-kix_list_16-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_16-1, lower-latin) ") ";
      }
      ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0;
      }
      .lst-kix_list_7-3 > li {
        counter-increment: lst-ctn-kix_list_7-3;
      }
      .lst-kix_list_16-2 > li:before {
        content: "" counter(lst-ctn-kix_list_16-2, lower-roman) ") ";
      }
      .lst-kix_list_16-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_16-5, lower-roman) ") ";
      }
      .lst-kix_list_15-3 > li {
        counter-increment: lst-ctn-kix_list_15-3;
      }
      ol.lst-kix_list_7-8.start {
        counter-reset: lst-ctn-kix_list_7-8 0;
      }
      .lst-kix_list_12-4 > li {
        counter-increment: lst-ctn-kix_list_12-4;
      }
      ol.lst-kix_list_11-0.start {
        counter-reset: lst-ctn-kix_list_11-0 8;
      }
      .lst-kix_list_3-3 > li {
        counter-increment: lst-ctn-kix_list_3-3;
      }
      .lst-kix_list_16-4 > li {
        counter-increment: lst-ctn-kix_list_16-4;
      }
      ol.lst-kix_list_6-3.start {
        counter-reset: lst-ctn-kix_list_6-3 0;
      }
      ol.lst-kix_list_10-2.start {
        counter-reset: lst-ctn-kix_list_10-2 0;
      }
      .lst-kix_list_12-7 > li {
        counter-increment: lst-ctn-kix_list_12-7;
      }
      .lst-kix_list_17-2 > li:before {
        content: "" counter(lst-ctn-kix_list_17-2, lower-roman) ") ";
      }
      ol.lst-kix_list_5-5.start {
        counter-reset: lst-ctn-kix_list_5-5 0;
      }
      ol.lst-kix_list_16-5.start {
        counter-reset: lst-ctn-kix_list_16-5 0;
      }
      ol.lst-kix_list_17-3.start {
        counter-reset: lst-ctn-kix_list_17-3 0;
      }
      .lst-kix_list_17-6 > li:before {
        content: "" counter(lst-ctn-kix_list_17-6, decimal) ". ";
      }
      .lst-kix_list_17-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_17-5, lower-roman) ") ";
      }
      .lst-kix_list_6-2 > li {
        counter-increment: lst-ctn-kix_list_6-2;
      }
      .lst-kix_list_2-6 > li:before {
        content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
      }
      ol.lst-kix_list_16-2.start {
        counter-reset: lst-ctn-kix_list_16-2 0;
      }
      .lst-kix_list_14-5 > li {
        counter-increment: lst-ctn-kix_list_14-5;
      }
      .lst-kix_list_7-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_7-1, lower-latin) ") ";
      }
      .lst-kix_list_7-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_7-5, lower-roman) ") ";
      }
      .lst-kix_list_13-5 > li {
        counter-increment: lst-ctn-kix_list_13-5;
      }
      .lst-kix_list_9-6 > li {
        counter-increment: lst-ctn-kix_list_9-6;
      }
      ol.lst-kix_list_5-4.start {
        counter-reset: lst-ctn-kix_list_5-4 0;
      }
      ol.lst-kix_list_11-1.start {
        counter-reset: lst-ctn-kix_list_11-1 0;
      }
      .lst-kix_list_13-6 > li:before {
        content: "" counter(lst-ctn-kix_list_13-6, decimal) ". ";
      }
      ol.lst-kix_list_5-1.start {
        counter-reset: lst-ctn-kix_list_5-1 0;
      }
      .lst-kix_list_6-7 > li {
        counter-increment: lst-ctn-kix_list_6-7;
      }
      .lst-kix_list_16-6 > li {
        counter-increment: lst-ctn-kix_list_16-6;
      }
      .lst-kix_list_10-6 > li {
        counter-increment: lst-ctn-kix_list_10-6;
      }
      .lst-kix_list_11-6 > li {
        counter-increment: lst-ctn-kix_list_11-6;
      }
      .lst-kix_list_1-7 > li {
        counter-increment: lst-ctn-kix_list_1-7;
      }
      ol.lst-kix_list_10-0.start {
        counter-reset: lst-ctn-kix_list_10-0 0;
      }
      .lst-kix_list_7-5 > li {
        counter-increment: lst-ctn-kix_list_7-5;
      }
      .lst-kix_list_15-6 > li:before {
        content: "" counter(lst-ctn-kix_list_15-6, decimal) ". ";
      }
      ol.lst-kix_list_17-8.start {
        counter-reset: lst-ctn-kix_list_17-8 0;
      }
      .lst-kix_list_11-4 > li {
        counter-increment: lst-ctn-kix_list_11-4;
      }
      .lst-kix_list_3-8 > li {
        counter-increment: lst-ctn-kix_list_3-8;
      }
      ol.lst-kix_list_6-8.start {
        counter-reset: lst-ctn-kix_list_6-8 0;
      }
      .lst-kix_list_10-2 > li:before {
        content: "(" counter(lst-ctn-kix_list_10-2, lower-roman) ") ";
      }
      .lst-kix_list_4-6 > li {
        counter-increment: lst-ctn-kix_list_4-6;
      }
      .lst-kix_list_13-7 > li {
        counter-increment: lst-ctn-kix_list_13-7;
      }
      ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0;
      }
      .lst-kix_list_1-5 > li {
        counter-increment: lst-ctn-kix_list_1-5;
      }
      ol.lst-kix_list_17-5.start {
        counter-reset: lst-ctn-kix_list_17-5 0;
      }
      ol.lst-kix_list_6-5.start {
        counter-reset: lst-ctn-kix_list_6-5 0;
      }
      .lst-kix_list_4-2 > li:before {
        content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ") ";
      }
      .lst-kix_list_4-6 > li:before {
        content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
      }
      .lst-kix_list_17-4 > li {
        counter-increment: lst-ctn-kix_list_17-4;
      }
      .lst-kix_list_15-2 > li:before {
        content: "" counter(lst-ctn-kix_list_15-2, lower-roman) ") ";
      }
      .lst-kix_list_10-8 > li {
        counter-increment: lst-ctn-kix_list_10-8;
      }
      .lst-kix_list_10-6 > li:before {
        content: "" counter(lst-ctn-kix_list_10-6, decimal) ". ";
      }
      .lst-kix_list_9-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-1, lower-latin) ") ";
      }
      ol.lst-kix_list_12-7.start {
        counter-reset: lst-ctn-kix_list_12-7 0;
      }
      .lst-kix_list_15-8 > li {
        counter-increment: lst-ctn-kix_list_15-8;
      }
      ol.lst-kix_list_6-7.start {
        counter-reset: lst-ctn-kix_list_6-7 0;
      }
      .lst-kix_list_12-2 > li {
        counter-increment: lst-ctn-kix_list_12-2;
      }
      .lst-kix_list_9-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-5, lower-roman) ") ";
      }
      li.li-bullet-5:before {
        margin-left: -17.7pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 17.7pt;
      }
      .lst-kix_list_12-2 > li:before {
        content: "" counter(lst-ctn-kix_list_12-2, lower-roman) ") ";
      }
      ol.lst-kix_list_12-8.start {
        counter-reset: lst-ctn-kix_list_12-8 0;
      }
      .lst-kix_list_11-6 > li:before {
        content: "" counter(lst-ctn-kix_list_11-6, decimal) ". ";
      }
      ol.lst-kix_list_11-3.start {
        counter-reset: lst-ctn-kix_list_11-3 0;
      }
      .lst-kix_list_1-2 > li:before {
        content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ") ";
      }
      .lst-kix_list_10-1 > li {
        counter-increment: lst-ctn-kix_list_10-1;
      }
      .lst-kix_list_1-0 > li {
        counter-increment: lst-ctn-kix_list_1-0;
      }
      .lst-kix_list_8-8 > li {
        counter-increment: lst-ctn-kix_list_8-8;
      }
      ol.lst-kix_list_16-1.start {
        counter-reset: lst-ctn-kix_list_16-1 0;
      }
      ol.lst-kix_list_17-6.start {
        counter-reset: lst-ctn-kix_list_17-6 0;
      }
      .lst-kix_list_1-6 > li:before {
        content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
      }
      li.li-bullet-0:before {
        margin-left: -17.9pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 17.9pt;
      }
      .lst-kix_list_12-6 > li:before {
        content: "" counter(lst-ctn-kix_list_12-6, decimal) ". ";
      }
      ol.lst-kix_list_11-4.start {
        counter-reset: lst-ctn-kix_list_11-4 0;
      }
      .lst-kix_list_2-2 > li:before {
        content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ") ";
      }
      .lst-kix_list_13-2 > li:before {
        content: "" counter(lst-ctn-kix_list_13-2, lower-roman) ") ";
      }
      ol.lst-kix_list_5-2.start {
        counter-reset: lst-ctn-kix_list_5-2 0;
      }
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c5 {
        -webkit-text-decoration-skip: none;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 11pt;
        font-style: normal;
      }
      .c8 {
        margin-left: 18pt;
        padding-top: 0pt;
        padding-left: -0.1pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c3 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: -0.3pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c6 {
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-style: normal;
      }
      .c13 {
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-style: normal;
      }
      .c2 {
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-style: normal;
      }
      .c19 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: right;
        height: 12pt;
      }
      .c0 {
        margin-left: 17.9pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c16 {
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-style: normal;
      }
      .c1 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c9 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c23 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      .c14 {
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt;
      }
      .c25 {
        margin-left: 35.9pt;
        padding-left: -0.4pt;
      }
      .c4 {
        padding: 0;
        margin: 0;
      }
      .c21 {
        margin-left: 54pt;
      }
      .c22 {
        padding-left: 0pt;
      }
      .c27 {
        margin-right: 0.2pt;
      }
      .c15 {
        margin-left: 14.2pt;
      }
      .c10 {
        margin-left: 18pt;
      }
      .c12 {
        margin-left: 35.7pt;
      }
      .c18 {
        margin-left: 21.2pt;
      }
      .c17 {
        margin-left: 17.9pt;
      }
      .c7 {
        page-break-after: avoid;
      }
      .c24 {
        margin-left: 35.5pt;
      }
      .c26 {
        height: 12pt;
      }
      .c11 {
        margin-left: 21.3pt;
      }
      .c20 {
        background-color: #00ff00;
      }
      .title {
        padding-top: 24pt;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        font-size: 24pt;
        padding-bottom: 4pt;
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        font-size: 12pt;
      }
      p {
        margin: 0;
        font-size: 12pt;
      }
      h1 {
        padding-top: 24pt;
        font-weight: 700;
        font-size: 20pt;
        padding-bottom: 24pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 18pt;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 14pt;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 12pt;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 11pt;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 10pt;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    }
  }
}

@import "../../index.scss";

.terms-component {
  color: $color-text-white1;
  .section-main {
    padding: 160px 0px;

    h2 {
      font-size: 20px !important;
      margin-bottom: 32px;
      margin-top: -16px;
      color: $color-text-black1;
    }

    .content {
      .lst-kix_list_4-1 > li {
        counter-increment: lst-ctn-kix_list_4-1;
      }
      ol.lst-kix_list_7-0 {
        list-style-type: none;
      }
      .lst-kix_list_2-1 > li {
        counter-increment: lst-ctn-kix_list_2-1;
      }
      ol.lst-kix_list_9-0.start {
        counter-reset: lst-ctn-kix_list_9-0 11;
      }
      .lst-kix_list_6-1 > li {
        counter-increment: lst-ctn-kix_list_6-1;
      }
      .lst-kix_list_8-1 > li {
        counter-increment: lst-ctn-kix_list_8-1;
      }
      ol.lst-kix_list_8-2.start {
        counter-reset: lst-ctn-kix_list_8-2 0;
      }
      ol.lst-kix_list_3-1.start {
        counter-reset: lst-ctn-kix_list_3-1 0;
      }
      ol.lst-kix_list_6-6.start {
        counter-reset: lst-ctn-kix_list_6-6 0;
      }
      ol.lst-kix_list_7-4.start {
        counter-reset: lst-ctn-kix_list_7-4 0;
      }
      .lst-kix_list_5-0 > li {
        counter-increment: lst-ctn-kix_list_5-0;
      }
      .lst-kix_list_7-0 > li {
        counter-increment: lst-ctn-kix_list_7-0;
      }
      .lst-kix_list_9-0 > li {
        counter-increment: lst-ctn-kix_list_9-0;
      }
      ol.lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0;
      }
      ol.lst-kix_list_7-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0;
      }
      ol.lst-kix_list_7-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-6.start {
        counter-reset: lst-ctn-kix_list_9-6 0;
      }
      ol.lst-kix_list_5-3.start {
        counter-reset: lst-ctn-kix_list_5-3 0;
      }
      .lst-kix_list_2-3 > li {
        counter-increment: lst-ctn-kix_list_2-3;
      }
      .lst-kix_list_4-3 > li {
        counter-increment: lst-ctn-kix_list_4-3;
      }
      ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0;
      }
      .lst-kix_list_1-2 > li {
        counter-increment: lst-ctn-kix_list_1-2;
      }
      ol.lst-kix_list_3-7.start {
        counter-reset: lst-ctn-kix_list_3-7 0;
      }
      .lst-kix_list_5-2 > li {
        counter-increment: lst-ctn-kix_list_5-2;
      }
      ol.lst-kix_list_8-8.start {
        counter-reset: lst-ctn-kix_list_8-8 0;
      }
      .lst-kix_list_3-2 > li {
        counter-increment: lst-ctn-kix_list_3-2;
      }
      .lst-kix_list_7-2 > li {
        counter-increment: lst-ctn-kix_list_7-2;
      }
      .lst-kix_list_9-2 > li {
        counter-increment: lst-ctn-kix_list_9-2;
      }
      ol.lst-kix_list_8-7.start {
        counter-reset: lst-ctn-kix_list_8-7 0;
      }
      .lst-kix_list_5-0 > li:before {
        content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
      }
      ol.lst-kix_list_6-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-1 {
        list-style-type: none;
      }
      .lst-kix_list_5-4 > li {
        counter-increment: lst-ctn-kix_list_5-4;
      }
      .lst-kix_list_1-4 > li {
        counter-increment: lst-ctn-kix_list_1-4;
      }
      ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0;
      }
      .lst-kix_list_5-3 > li:before {
        content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
      }
      ol.lst-kix_list_9-5.start {
        counter-reset: lst-ctn-kix_list_9-5 0;
      }
      .lst-kix_list_5-2 > li:before {
        content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ". ";
      }
      .lst-kix_list_8-3 > li {
        counter-increment: lst-ctn-kix_list_8-3;
      }
      .lst-kix_list_5-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-1, lower-latin) ") ";
      }
      .lst-kix_list_5-7 > li:before {
        content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". ";
      }
      .lst-kix_list_5-6 > li:before {
        content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
      }
      .lst-kix_list_5-8 > li:before {
        content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". ";
      }
      .lst-kix_list_9-4 > li {
        counter-increment: lst-ctn-kix_list_9-4;
      }
      ol.lst-kix_list_6-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-7 {
        list-style-type: none;
      }
      .lst-kix_list_5-4 > li:before {
        content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". ";
      }
      ol.lst-kix_list_6-8 {
        list-style-type: none;
      }
      .lst-kix_list_5-5 > li:before {
        content: "" counter(lst-ctn-kix_list_5-5, lower-roman) ". ";
      }
      ol.lst-kix_list_6-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0;
      }
      .lst-kix_list_6-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-1, lower-latin) ") ";
      }
      .lst-kix_list_6-3 > li:before {
        content: "" counter(lst-ctn-kix_list_6-3, decimal) ". ";
      }
      .lst-kix_list_6-5 > li {
        counter-increment: lst-ctn-kix_list_6-5;
      }
      .lst-kix_list_6-8 > li {
        counter-increment: lst-ctn-kix_list_6-8;
      }
      .lst-kix_list_6-0 > li:before {
        content: "" counter(lst-ctn-kix_list_6-0, decimal) ". ";
      }
      .lst-kix_list_6-4 > li:before {
        content: "" counter(lst-ctn-kix_list_6-4, lower-latin) ". ";
      }
      .lst-kix_list_3-0 > li {
        counter-increment: lst-ctn-kix_list_3-0;
      }
      ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0;
      }
      .lst-kix_list_3-6 > li {
        counter-increment: lst-ctn-kix_list_3-6;
      }
      .lst-kix_list_6-2 > li:before {
        content: "" counter(lst-ctn-kix_list_6-2, lower-roman) ". ";
      }
      .lst-kix_list_2-5 > li {
        counter-increment: lst-ctn-kix_list_2-5;
      }
      .lst-kix_list_2-8 > li {
        counter-increment: lst-ctn-kix_list_2-8;
      }
      ol.lst-kix_list_3-2.start {
        counter-reset: lst-ctn-kix_list_3-2 0;
      }
      .lst-kix_list_6-8 > li:before {
        content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ". ";
      }
      .lst-kix_list_6-5 > li:before {
        content: "" counter(lst-ctn-kix_list_6-5, lower-roman) ". ";
      }
      .lst-kix_list_6-7 > li:before {
        content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ". ";
      }
      ol.lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0;
      }
      .lst-kix_list_6-6 > li:before {
        content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
      }
      ol.lst-kix_list_1-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-4 {
        list-style-type: none;
      }
      .lst-kix_list_2-7 > li:before {
        content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
      }
      .lst-kix_list_2-7 > li {
        counter-increment: lst-ctn-kix_list_2-7;
      }
      ol.lst-kix_list_1-5 {
        list-style-type: none;
      }
      .lst-kix_list_7-4 > li:before {
        content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". ";
      }
      .lst-kix_list_7-6 > li:before {
        content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
      }
      ol.lst-kix_list_1-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-0 {
        list-style-type: none;
      }
      .lst-kix_list_2-5 > li:before {
        content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
      }
      ol.lst-kix_list_6-2.start {
        counter-reset: lst-ctn-kix_list_6-2 0;
      }
      ol.lst-kix_list_1-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-2 {
        list-style-type: none;
      }
      .lst-kix_list_7-2 > li:before {
        content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". ";
      }
      .lst-kix_list_7-6 > li {
        counter-increment: lst-ctn-kix_list_7-6;
      }
      .lst-kix_list_8-6 > li {
        counter-increment: lst-ctn-kix_list_8-6;
      }
      ol.lst-kix_list_9-4.start {
        counter-reset: lst-ctn-kix_list_9-4 0;
      }
      ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0;
      }
      ol.lst-kix_list_9-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0;
      }
      ol.lst-kix_list_9-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-4 {
        list-style-type: none;
      }
      .lst-kix_list_5-7 > li {
        counter-increment: lst-ctn-kix_list_5-7;
      }
      .lst-kix_list_7-7 > li {
        counter-increment: lst-ctn-kix_list_7-7;
      }
      ol.lst-kix_list_9-5 {
        list-style-type: none;
      }
      .lst-kix_list_7-8 > li:before {
        content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". ";
      }
      ol.lst-kix_list_9-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0;
      }
      ol.lst-kix_list_1-7 {
        list-style-type: none;
      }
      .lst-kix_list_4-7 > li {
        counter-increment: lst-ctn-kix_list_4-7;
      }
      ol.lst-kix_list_9-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-2 {
        list-style-type: none;
      }
      .lst-kix_list_7-8 > li {
        counter-increment: lst-ctn-kix_list_7-8;
      }
      ol.lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0;
      }
      .lst-kix_list_9-8 > li {
        counter-increment: lst-ctn-kix_list_9-8;
      }
      .lst-kix_list_2-6 > li {
        counter-increment: lst-ctn-kix_list_2-6;
      }
      .lst-kix_list_4-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_4-1, lower-latin) ") ";
      }
      ol.lst-kix_list_7-3.start {
        counter-reset: lst-ctn-kix_list_7-3 0;
      }
      .lst-kix_list_9-2 > li:before {
        content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ". ";
      }
      .lst-kix_list_4-3 > li:before {
        content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
      }
      .lst-kix_list_4-5 > li:before {
        content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". ";
      }
      ol.lst-kix_list_5-7.start {
        counter-reset: lst-ctn-kix_list_5-7 0;
      }
      .lst-kix_list_1-8 > li {
        counter-increment: lst-ctn-kix_list_1-8;
      }
      ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0;
      }
      .lst-kix_list_5-5 > li {
        counter-increment: lst-ctn-kix_list_5-5;
      }
      .lst-kix_list_3-5 > li {
        counter-increment: lst-ctn-kix_list_3-5;
      }
      ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0;
      }
      .lst-kix_list_9-0 > li:before {
        content: "" counter(lst-ctn-kix_list_9-0, decimal) ". ";
      }
      .lst-kix_list_3-4 > li {
        counter-increment: lst-ctn-kix_list_3-4;
      }
      ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0;
      }
      .lst-kix_list_6-4 > li {
        counter-increment: lst-ctn-kix_list_6-4;
      }
      ol.lst-kix_list_9-2.start {
        counter-reset: lst-ctn-kix_list_9-2 0;
      }
      .lst-kix_list_9-6 > li:before {
        content: "" counter(lst-ctn-kix_list_9-6, decimal) ". ";
      }
      .lst-kix_list_9-3 > li {
        counter-increment: lst-ctn-kix_list_9-3;
      }
      .lst-kix_list_9-4 > li:before {
        content: "" counter(lst-ctn-kix_list_9-4, lower-latin) ". ";
      }
      .lst-kix_list_6-3 > li {
        counter-increment: lst-ctn-kix_list_6-3;
      }
      ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0;
      }
      ol.lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0;
      }
      ol.lst-kix_list_8-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0;
      }
      ol.lst-kix_list_7-6.start {
        counter-reset: lst-ctn-kix_list_7-6 0;
      }
      ol.lst-kix_list_6-1.start {
        counter-reset: lst-ctn-kix_list_6-1 0;
      }
      ol.lst-kix_list_8-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-0 {
        list-style-type: none;
      }
      .lst-kix_list_9-8 > li:before {
        content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". ";
      }
      ol.lst-kix_list_8-1 {
        list-style-type: none;
      }
      .lst-kix_list_1-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_1-1, lower-latin) ") ";
      }
      ol.lst-kix_list_8-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-3 {
        list-style-type: none;
      }
      .lst-kix_list_8-5 > li {
        counter-increment: lst-ctn-kix_list_8-5;
      }
      .lst-kix_list_1-3 > li:before {
        content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
      }
      .lst-kix_list_4-8 > li {
        counter-increment: lst-ctn-kix_list_4-8;
      }
      .lst-kix_list_1-7 > li:before {
        content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
      }
      ol.lst-kix_list_5-8.start {
        counter-reset: lst-ctn-kix_list_5-8 0;
      }
      ol.lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0;
      }
      .lst-kix_list_1-3 > li {
        counter-increment: lst-ctn-kix_list_1-3;
      }
      .lst-kix_list_1-5 > li:before {
        content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". ";
      }
      ol.lst-kix_list_9-1.start {
        counter-reset: lst-ctn-kix_list_9-1 0;
      }
      .lst-kix_list_5-6 > li {
        counter-increment: lst-ctn-kix_list_5-6;
      }
      ol.lst-kix_list_7-5.start {
        counter-reset: lst-ctn-kix_list_7-5 0;
      }
      .lst-kix_list_2-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-1, lower-latin) ") ";
      }
      ol.lst-kix_list_6-0.start {
        counter-reset: lst-ctn-kix_list_6-0 6;
      }
      .lst-kix_list_2-3 > li:before {
        content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
      }
      .lst-kix_list_4-2 > li {
        counter-increment: lst-ctn-kix_list_4-2;
      }
      ol.lst-kix_list_3-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-2 {
        list-style-type: none;
      }
      .lst-kix_list_3-1 > li {
        counter-increment: lst-ctn-kix_list_3-1;
      }
      ol.lst-kix_list_3-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-4.start {
        counter-reset: lst-ctn-kix_list_3-4 0;
      }
      .lst-kix_list_5-1 > li {
        counter-increment: lst-ctn-kix_list_5-1;
      }
      ol.lst-kix_list_3-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-0 {
        list-style-type: none;
      }
      .lst-kix_list_1-1 > li {
        counter-increment: lst-ctn-kix_list_1-1;
      }
      .lst-kix_list_7-1 > li {
        counter-increment: lst-ctn-kix_list_7-1;
      }
      .lst-kix_list_9-1 > li {
        counter-increment: lst-ctn-kix_list_9-1;
      }
      ol.lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0;
      }
      .lst-kix_list_3-0 > li:before {
        content: "" counter(lst-ctn-kix_list_3-0, decimal) ". ";
      }
      ol.lst-kix_list_7-7.start {
        counter-reset: lst-ctn-kix_list_7-7 0;
      }
      .lst-kix_list_3-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-1, lower-latin) ") ";
      }
      .lst-kix_list_3-2 > li:before {
        content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". ";
      }
      .lst-kix_list_8-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_8-1, lower-latin) ") ";
      }
      ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0;
      }
      .lst-kix_list_4-0 > li {
        counter-increment: lst-ctn-kix_list_4-0;
      }
      .lst-kix_list_8-2 > li:before {
        content: "" counter(lst-ctn-kix_list_8-2, lower-roman) ". ";
      }
      .lst-kix_list_6-0 > li {
        counter-increment: lst-ctn-kix_list_6-0;
      }
      .lst-kix_list_8-0 > li {
        counter-increment: lst-ctn-kix_list_8-0;
      }
      .lst-kix_list_3-5 > li:before {
        content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". ";
      }
      .lst-kix_list_3-4 > li:before {
        content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". ";
      }
      .lst-kix_list_3-3 > li:before {
        content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
      }
      ol.lst-kix_list_3-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-6 {
        list-style-type: none;
      }
      .lst-kix_list_8-0 > li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) ". ";
      }
      ol.lst-kix_list_3-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-8 {
        list-style-type: none;
      }
      .lst-kix_list_8-7 > li:before {
        content: "" counter(lst-ctn-kix_list_8-7, lower-latin) ". ";
      }
      .lst-kix_list_3-8 > li:before {
        content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
      }
      .lst-kix_list_8-5 > li:before {
        content: "" counter(lst-ctn-kix_list_8-5, lower-roman) ". ";
      }
      .lst-kix_list_8-6 > li:before {
        content: "" counter(lst-ctn-kix_list_8-6, decimal) ". ";
      }
      .lst-kix_list_2-0 > li {
        counter-increment: lst-ctn-kix_list_2-0;
      }
      .lst-kix_list_8-3 > li:before {
        content: "" counter(lst-ctn-kix_list_8-3, decimal) ". ";
      }
      .lst-kix_list_3-6 > li:before {
        content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
      }
      .lst-kix_list_3-7 > li:before {
        content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
      }
      .lst-kix_list_8-4 > li:before {
        content: "" counter(lst-ctn-kix_list_8-4, lower-latin) ". ";
      }
      ol.lst-kix_list_5-0.start {
        counter-reset: lst-ctn-kix_list_5-0 0;
      }
      ol.lst-kix_list_8-5.start {
        counter-reset: lst-ctn-kix_list_8-5 0;
      }
      ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0;
      }
      ol.lst-kix_list_9-3.start {
        counter-reset: lst-ctn-kix_list_9-3 0;
      }
      .lst-kix_list_8-8 > li:before {
        content: "" counter(lst-ctn-kix_list_8-8, lower-roman) ". ";
      }
      ol.lst-kix_list_2-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-2.start {
        counter-reset: lst-ctn-kix_list_7-2 0;
      }
      ol.lst-kix_list_2-5 {
        list-style-type: none;
      }
      .lst-kix_list_4-4 > li {
        counter-increment: lst-ctn-kix_list_4-4;
      }
      ol.lst-kix_list_2-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-1 {
        list-style-type: none;
      }
      .lst-kix_list_4-8 > li:before {
        content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". ";
      }
      ol.lst-kix_list_6-4.start {
        counter-reset: lst-ctn-kix_list_6-4 0;
      }
      .lst-kix_list_4-7 > li:before {
        content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". ";
      }
      ol.lst-kix_list_5-6.start {
        counter-reset: lst-ctn-kix_list_5-6 0;
      }
      ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0;
      }
      .lst-kix_list_7-3 > li {
        counter-increment: lst-ctn-kix_list_7-3;
      }
      ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0;
      }
      .lst-kix_list_8-4 > li {
        counter-increment: lst-ctn-kix_list_8-4;
      }
      ol.lst-kix_list_3-3.start {
        counter-reset: lst-ctn-kix_list_3-3 0;
      }
      ol.lst-kix_list_2-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-8.start {
        counter-reset: lst-ctn-kix_list_7-8 0;
      }
      ol.lst-kix_list_7-1.start {
        counter-reset: lst-ctn-kix_list_7-1 0;
      }
      ol.lst-kix_list_8-6.start {
        counter-reset: lst-ctn-kix_list_8-6 0;
      }
      .lst-kix_list_3-3 > li {
        counter-increment: lst-ctn-kix_list_3-3;
      }
      ol.lst-kix_list_6-3.start {
        counter-reset: lst-ctn-kix_list_6-3 0;
      }
      ol.lst-kix_list_5-5.start {
        counter-reset: lst-ctn-kix_list_5-5 0;
      }
      ol.lst-kix_list_8-0.start {
        counter-reset: lst-ctn-kix_list_8-0 0;
      }
      .lst-kix_list_7-0 > li:before {
        content: "" counter(lst-ctn-kix_list_7-0, decimal) ". ";
      }
      .lst-kix_list_2-2 > li {
        counter-increment: lst-ctn-kix_list_2-2;
      }
      ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0;
      }
      .lst-kix_list_6-2 > li {
        counter-increment: lst-ctn-kix_list_6-2;
      }
      ol.lst-kix_list_5-0 {
        list-style-type: none;
      }
      .lst-kix_list_2-6 > li:before {
        content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
      }
      .lst-kix_list_3-7 > li {
        counter-increment: lst-ctn-kix_list_3-7;
      }
      ol.lst-kix_list_5-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-7.start {
        counter-reset: lst-ctn-kix_list_9-7 0;
      }
      ol.lst-kix_list_5-2 {
        list-style-type: none;
      }
      .lst-kix_list_2-4 > li:before {
        content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
      }
      .lst-kix_list_2-8 > li:before {
        content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
      }
      .lst-kix_list_7-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_7-1, lower-latin) ") ";
      }
      .lst-kix_list_7-5 > li:before {
        content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". ";
      }
      .lst-kix_list_9-6 > li {
        counter-increment: lst-ctn-kix_list_9-6;
      }
      .lst-kix_list_6-6 > li {
        counter-increment: lst-ctn-kix_list_6-6;
      }
      ol.lst-kix_list_5-4.start {
        counter-reset: lst-ctn-kix_list_5-4 0;
      }
      .lst-kix_list_7-3 > li:before {
        content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
      }
      .lst-kix_list_9-7 > li {
        counter-increment: lst-ctn-kix_list_9-7;
      }
      ol.lst-kix_list_5-1.start {
        counter-reset: lst-ctn-kix_list_5-1 0;
      }
      ol.lst-kix_list_5-7 {
        list-style-type: none;
      }
      .lst-kix_list_6-7 > li {
        counter-increment: lst-ctn-kix_list_6-7;
      }
      ol.lst-kix_list_5-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_5-3 {
        list-style-type: none;
      }
      .lst-kix_list_8-7 > li {
        counter-increment: lst-ctn-kix_list_8-7;
      }
      ol.lst-kix_list_5-4 {
        list-style-type: none;
      }
      .lst-kix_list_1-7 > li {
        counter-increment: lst-ctn-kix_list_1-7;
      }
      ol.lst-kix_list_3-8.start {
        counter-reset: lst-ctn-kix_list_3-8 0;
      }
      ol.lst-kix_list_5-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_5-6 {
        list-style-type: none;
      }
      .lst-kix_list_7-7 > li:before {
        content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". ";
      }
      ol.lst-kix_list_8-1.start {
        counter-reset: lst-ctn-kix_list_8-1 0;
      }
      .lst-kix_list_7-5 > li {
        counter-increment: lst-ctn-kix_list_7-5;
      }
      .lst-kix_list_9-5 > li {
        counter-increment: lst-ctn-kix_list_9-5;
      }
      .lst-kix_list_5-8 > li {
        counter-increment: lst-ctn-kix_list_5-8;
      }
      .lst-kix_list_4-0 > li:before {
        content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
      }
      .lst-kix_list_3-8 > li {
        counter-increment: lst-ctn-kix_list_3-8;
      }
      ol.lst-kix_list_6-8.start {
        counter-reset: lst-ctn-kix_list_6-8 0;
      }
      .lst-kix_list_4-6 > li {
        counter-increment: lst-ctn-kix_list_4-6;
      }
      ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0;
      }
      .lst-kix_list_4-4 > li:before {
        content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". ";
      }
      ol.lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0;
      }
      .lst-kix_list_1-5 > li {
        counter-increment: lst-ctn-kix_list_1-5;
      }
      ol.lst-kix_list_6-5.start {
        counter-reset: lst-ctn-kix_list_6-5 0;
      }
      .lst-kix_list_4-2 > li:before {
        content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". ";
      }
      .lst-kix_list_4-6 > li:before {
        content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
      }
      .lst-kix_list_9-3 > li:before {
        content: "" counter(lst-ctn-kix_list_9-3, decimal) ". ";
      }
      ol.lst-kix_list_7-0.start {
        counter-reset: lst-ctn-kix_list_7-0 0;
      }
      .lst-kix_list_9-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-1, lower-latin) ") ";
      }
      ol.lst-kix_list_4-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-3 {
        list-style-type: none;
      }
      .lst-kix_list_9-7 > li:before {
        content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". ";
      }
      .lst-kix_list_2-4 > li {
        counter-increment: lst-ctn-kix_list_2-4;
      }
      ol.lst-kix_list_6-7.start {
        counter-reset: lst-ctn-kix_list_6-7 0;
      }
      ol.lst-kix_list_3-6.start {
        counter-reset: lst-ctn-kix_list_3-6 0;
      }
      .lst-kix_list_9-5 > li:before {
        content: "" counter(lst-ctn-kix_list_9-5, lower-roman) ". ";
      }
      .lst-kix_list_5-3 > li {
        counter-increment: lst-ctn-kix_list_5-3;
      }
      ol.lst-kix_list_4-8 {
        list-style-type: none;
      }
      .lst-kix_list_7-4 > li {
        counter-increment: lst-ctn-kix_list_7-4;
      }
      .lst-kix_list_1-0 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
      }
      ol.lst-kix_list_4-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-5 {
        list-style-type: none;
      }
      .lst-kix_list_1-2 > li:before {
        content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". ";
      }
      ol.lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0;
      }
      ol.lst-kix_list_4-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-4.start {
        counter-reset: lst-ctn-kix_list_8-4 0;
      }
      .lst-kix_list_1-4 > li:before {
        content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". ";
      }
      ol.lst-kix_list_3-5.start {
        counter-reset: lst-ctn-kix_list_3-5 0;
      }
      .lst-kix_list_1-0 > li {
        counter-increment: lst-ctn-kix_list_1-0;
      }
      .lst-kix_list_8-8 > li {
        counter-increment: lst-ctn-kix_list_8-8;
      }
      .lst-kix_list_1-6 > li {
        counter-increment: lst-ctn-kix_list_1-6;
      }
      .lst-kix_list_1-6 > li:before {
        content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
      }
      li.li-bullet-0:before {
        margin-left: -17.9pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 17.9pt;
      }
      .lst-kix_list_2-0 > li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
      }
      ol.lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0;
      }
      ol.lst-kix_list_8-3.start {
        counter-reset: lst-ctn-kix_list_8-3 0;
      }
      .lst-kix_list_4-5 > li {
        counter-increment: lst-ctn-kix_list_4-5;
      }
      ol.lst-kix_list_9-8.start {
        counter-reset: lst-ctn-kix_list_9-8 0;
      }
      .lst-kix_list_1-8 > li:before {
        content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
      }
      .lst-kix_list_2-2 > li:before {
        content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
      }
      ol.lst-kix_list_5-2.start {
        counter-reset: lst-ctn-kix_list_5-2 0;
      }
      .lst-kix_list_8-2 > li {
        counter-increment: lst-ctn-kix_list_8-2;
      }
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c8 {
        -webkit-text-decoration-skip: none;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 11pt;
        font-style: normal;
        padding-bottom: 16px;
        display: inline-block;
      }
      .c6 {
        margin-left: 18pt;
        padding-top: 0pt;
        padding-left: -0.1pt;
        padding-bottom: 0pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c4 {
        margin-left: 35.9pt;
        padding-top: 0pt;
        padding-left: -0.1pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c2 {
        margin-left: 17.9pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c14 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: right;
        height: 12pt;
      }
      .c16 {
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-style: normal;
      }
      .c5 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 12pt;
      }
      .c7 {
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-style: normal;
      }
      .c9 {
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-style: normal;
      }
      .c0 {
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-style: normal;
      }
      .c3 {
        margin-left: 35.7pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c11 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c15 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      .c13 {
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt;
      }
      .c1 {
        padding: 0;
        margin: 0;
      }
      .c10 {
      }
      .c12 {
        page-break-after: avoid;
      }
      .title {
        padding-top: 24pt;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        font-size: 24pt;
        padding-bottom: 4pt;
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        font-size: 12pt;
      }
      p {
        margin: 0;
        font-size: 12pt;
      }
      h1 {
        padding-top: 24pt;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 18pt;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 14pt;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 12pt;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 11pt;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 10pt;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    }
  }
}

@import "../../index.scss";

.contact-component {
  padding: 32px 0px 74px 0px;

  .contact-card {
    border-radius: 22px;
    background: $color-background-black1;
    padding: 32px;
  }

  button {
    width: 100%;
  }

  .MuiFormLabel-root {
    margin: 8px 0px;
  }

  .MuiFormLabel-root,
  .MuiInputLabel-root,
  .MuiFormControl-root {
    color: $color-text-white2;
  }
  .MuiInputBase-root,
  .MuiInputBase-input {
    color: $color-text-white1;
  }

  .MuiInputBase-root.Mui-focused::after {
    border-bottom: 1px solid $color-text-white2;
    transform: scaleX(1) translateX(0);
  }

  .MuiInputBase-root::after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    transform: scaleX(1) translateX(0);
  }
}

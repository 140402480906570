@import "../../index.scss";

.technology-component {
  .section-main {
    padding-top: 74px;
    background-size: cover;
    text-align: center;
    position: relative;
    .container {
      position: relative;
    }
    .row {
      height: 468px;
    }
    video {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      width: 100%;
      height: 540px;
      object-fit: cover;
    }
    .mask {
      background: rgba($color: #000000, $alpha: 0.5);
      position: absolute;
      top: 0px;
      height: 100%;
      width: 100%;
    }
    .section-main-content {
      padding: 16px;
      span {
        display: inline-block;
      }
    }
    @media (max-width: 991px) {
      .section-main-content {
        span {
          display: block;
        }
      }
    }
  }

  .section-hero-video {
    color: $color-text-black1;
    position: relative;

    video {
      width: 100%;
      height: 520px;
      object-fit: cover;

      @media (max-width: 767px) {
        height: 480px;
      }

      @media (max-width: 520px) {
        height: 400px;
      }
    }
    @media (max-width: 991px) {
      .section-main-content {
        span {
          display: block;
        }
      }
    }
  }

  .section-technology {
    padding: 64px 0px 74px 0px;

    p {
      margin-bottom: 0px;
      color: $color-text-white1;
    }

    .MuiTab-root {
      color: $color-text-white1;
    }

    .section-description {
      display: inline-block;
      max-width: 780px;
      margin-bottom: 48px;
    }

    .svg-inline--fa {
      font-size: $font-size-lg;
      width: $font-size-lg;
      height: $font-size-lg;
    }

    .technology-subheading {
      color: $color-primary;
      font-size: $font-size-lg;
      display: inline-block;
      margin: 6px 0px;
    }

    .icon-container {
      display: inline-block;
      padding: 12px;
      border-radius: 100%;
      background: $color-primary;
      height: 44px;
      width: 44px;
      margin-right: 12px;
      margin-bottom: 16px;
    }

    .section-technology-image {
      text-align: center;
      img,
      video {
        width: 100%;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
          rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
          rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
      }
      @media (max-width: 991px) {
        img,
        video {
          width: 100%;
        }
      }

      @media (max-width: 991px) {
        margin-bottom: 48px;
      }
    }

    .MuiTab-labelIcon {
      text-transform: none !important;
      padding: 12px 12px !important;
    }

    .MuiTabs-root {
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 48px !important;
    }

    .Mui-selected {
      color: $color-accent;
    }

    .MuiTabs-indicator {
      background-color: $color-accent;
    }
  }

  .section-flux-platform {
    padding: 74px 0px 74px 0px;

    .card-title {
      color: $color-text-white1;
      font-size: $font-size-lg;
      margin-bottom: 0px;
      vertical-align: -3px;
    }

    p {
      color: $color-text-white2;
    }

    .MuiDivider-root {
      border-color: $color-text-black2;
      margin: 24px 8px;
      width: calc(100% - 16px);
    }

    .icon-container {
      padding: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      height: 96px;
      width: 96px;
      text-align: center;

      @media (max-width: 767px) {
        height: 72px;
        width: 72px;
      }
    }

    img {
      width: 100%;
    }
  }

  .section-safety {
    padding: 0px 0px 32px 0px;

    .card-title {
      color: $color-text-white1;
      font-size: $font-size-lg;
      margin-bottom: 0px;
      vertical-align: -3px;
      font-family: "Chakra Petch" !important;
    }

    .safety-card-container {
      display: flex;
      justify-content: center;
    }

    .safety-card {
      text-align: center;
      border-radius: 4px;
      padding: 16px;
      min-height: 160px;
      margin-bottom: 16px;

      .svg-inline--fa {
        color: $color-primary;
        font-size: 48px;
        margin-top: 16px;
        margin-bottom: 16px;
      }

      hr {
        margin: 16px 0px;
      }
    }

    @media (max-width: 482px) {
      .safety-card {
        min-height: 184px;
      }
    }
  }

  .section-prototype {
    background: $color-background-white1;
    padding: 74px 0px;

    .prototype-video-container {
      a {
        text-decoration: none;
      }
      .youtube-container {
        text-align: center;
        iframe {
          overflow: hidden;
          height: 280px;
          width: 100%;
          border-radius: 8px;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
            rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
        }
        @media (max-width: 991px) {
          iframe {
            height: 240px;
            width: 100%;
          }
        }
      }
    }
  }
}

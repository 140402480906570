@import "../../index.scss";

.section-investors {
  padding: 0px 0px 32px 0px;

  .image-container {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 48px;
  }
}

@import "../../index.scss";

.home-component {
  .section-hero-main {
    padding: 74px 0px 0px 0px;

    .slider {
      margin-top: -24px;
      margin-bottom: 24px;
      h1 {
        display: inline;
      }
      .col-6,
      .col-12 {
        padding: 0px;
      }
    }
    .button-learn-more {
      border-radius: 64px !important;
      text-transform: uppercase !important;
    }
  }

  .section-hero-video {
    color: $color-text-black1;
    position: relative;

    // video {
    //   width: 100%;
    //   height: 720px;
    //   object-fit: cover;

    //   @media (max-width: 767px) {
    //     height: 480px;
    //   }

    //   @media (max-width: 520px) {
    //     height: 400px;
    //   }
    // }

    .video-container {
      padding: 16px 0px;
      margin-bottom: 16px;
      position: relative;
      video {
        border-radius: 16px;
        width: 100%;
        height: 600px;
        object-fit: cover;
      }

      @media (max-width: 767px) {
        padding: 0px;
      }

      button {
        position: absolute;
        right: 16px;
        bottom: 36px;
      }
    }

    @media (max-width: 991px) {
      .section-main-content {
        span {
          display: block;
        }
      }
    }
  }

  .section-in-action {
    padding: 0px 0px 74px 0px;
    .button-order-now {
      border-radius: 64px !important;
      text-transform: uppercase !important;
      margin-top: 32px;
    }
  }

  .section-flux-platform {
    padding: 0px 0px 74px 0px;
    text-align: center;
    position: relative;

    .card-container {
      background: $color-background-black2;
      padding: 16px;
    }

    .card-details {
      border-radius: 16px;
      background: $color-background-black2;
      padding: 16px;

      .card-title {
        margin-bottom: 32px;
      }
    }

    .image-container {
      border-radius: 16px;
      padding: 32px;
      height: 194px;
      img {
        height: 120px;
        border-radius: 16px;
      }
    }

    .card-title {
      color: $color-text-white1;
      font-size: $font-size-xl;
      margin-bottom: 0px;
      margin-top: 24px;
      font-weight: 900;
      background: -webkit-linear-gradient(90deg, $color-primary, $color-accent);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      color: $color-text-white1;
    }
  }

  .section-why-automate {
    padding: 0px 0px 74px 0px;
    a {
      text-decoration: none;
    }

    .tile-card {
      margin-bottom: 16px;
      padding: 0px 16px 16px 16px;
      min-height: 140px;

      .image-container {
        display: inline-block;
        width: 48px;
        text-align: center;
        margin-left: -8px;
      }
      img {
        height: 28px;
        opacity: 0.8;
        margin-right: 8px;
      }
      p {
        font-size: $font-size-md;
        line-height: $font-size-xl;
        margin-top: 12px;
        margin-bottom: 0px;
        color: $color-text-white2;
      }
      .card-title {
        color: $color-text-white1;
        font-size: $font-size-lg;
        margin-bottom: 0px;
        font-family: "Chakra Petch" !important;
      }
    }
    @media (max-width: 991px) {
      padding-bottom: 32px;
      .card {
        margin-bottom: 16px;
      }
    }
  }

  .section-simple-but-mighty {
    padding: 0px 0px 74px 0px;
    color: $color-text-black1;
    h1 {
      text-align: center;
    }
    img {
      width: 100%;
    }
  }

  .section-why-choose {
    padding: 0px 0px 74px 0px;

    @media (max-width: 991px) {
      padding-bottom: 32px;
    }

    a {
      text-decoration: none;
    }

    h1 {
      color: $color-text-white1;
    }

    h2 {
      color: $color-text-white1;
    }

    .tile-card {
      margin-bottom: 16px;
      padding: 24px 16px 0px 16px;
      color: $color-text-white1;

      h2 {
        color: $color-text-black1;
        margin-bottom: 0px;
      }
      .image-container {
        display: inline-block;
        width: 48px;
        text-align: center;
        margin-left: -8px;
      }
      img {
        width: 28px;
        opacity: 0.8;
        margin-right: 8px;
      }
      p {
        font-size: $font-size-md;
        line-height: $font-size-xl;
        margin-top: 12px;
        margin-bottom: 0px;
        color: $color-text-white2;
      }
      .card-title {
        color: $color-text-white1;
        font-size: $font-size-lg;
        margin-bottom: 0px;
        font-family: "Chakra Petch" !important;
      }
    }
    @media (max-width: 991px) {
      .card {
        margin-bottom: 16px;
      }
    }
  }
}
